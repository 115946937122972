import * as AppConstants from '../../AppConstants';
import boostStyles from './BoostModalComp.module.css';
import genesisStyles from './GenesisModalComp.module.css';
import infiniteStyles from './InfiniteModalComp.module.css';
import CloseIcon from '../Icons/CloseIcon/CloseIcon';
import ModalMsgIcon from '../Icons/ModalMsgIcon/ModalMsgIcon';

function ModalComp(props) {
  let styles;
  switch (props.brandName) {
    case AppConstants.BOOST_BRAND:
      styles = boostStyles;
      break;
    case AppConstants.GENESIS_BRAND:
      styles = genesisStyles;
      break;
    case AppConstants.INFINITE_BRAND:
      styles = infiniteStyles;
      break;
    default:
      styles = boostStyles;
  }
  //const secondaryText = <span>{`${props.secText}`}</span>;
  return (<>
    {props.showComp && props.showComp === true ? (<div className={styles['bg-blur']} />) : null}
    <div className={props.showComp && props.showComp === true ? styles['modal-container'] : styles['modal-container-off']}>
      <div className={styles['flex-container']}>
        <span className={styles['close-btn']} onClick={e => props.closeModal(e)}> <CloseIcon brandName={props.brandName} /> </span>
        <h1 className={styles['modal-prim-text']}>{props.primText}</h1>
        {!props.noIcon ? <div className={styles['message-icon']}>
          <ModalMsgIcon brandName={props.brandName} />
        </div> : null}
        <p className={styles['modal-sec-text'] + " " + styles['leg-sec-text']} >{props.secText}</p>


        <button type="button" className={"modal--button--primary " + styles['btn']}
          onClick={e => {
            props.sendCode(e)
          }}
        >
          {props.btnText}
        </button>

        {!!props.showCancel ?  <button type="button" className={styles['btn-cancel']}
          onClick={e => props.closeModal(e)}
        >
          Cancel
        </button> : null}

      </div>
    </div>
  </>
  );
}

export default ModalComp;