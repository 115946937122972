import qs from "qs";
import axios from "axios";
import * as AppConstants from '../AppConstants';
import { generateRandomString, challenge_from_verifier } from '../Utitlity/PingCodeChallenge';

const pingHostURL = process.env.REACT_APP_PING_HOST;


function getError(code, msg) {
    return {
        'error': {
            'code': code,
            'message': msg
        }
    }
}

function getFlowID(redirectURL, pingClientID) {
    let flowId;
    const code_verifier = generateRandomString().slice(0, 43);
    const code_challenge = challenge_from_verifier(code_verifier);
    return code_challenge.then(challenge => {
        return axios({
            url: pingHostURL + '/as/authorization.oauth2?client_id=' + pingClientID + '&redirect_uri=' + redirectURL + '&response_type=code&response_mode=form_post&code_challenge_method=S256&code_challenge=' + challenge,
            method: 'get',
            withCredentials: true
        }).then(res => {
            const flowURLStr = res.request.responseURL;
            if (!flowURLStr || !flowURLStr.includes("flowId=")) {
                return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 1'));
            }
            flowId = flowURLStr.split("flowId=")[1];

            return Promise.resolve({
                code_verifier: code_verifier,
                flowId: flowId
            });

        }).catch(err => {
            return Promise.reject(getError('PING_ERROR', err));
        });
    });

}

function initiateAuthSess(flowId) {
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId,
        method: 'get',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate'
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));

    });
}

function postCredentials(email, pass, flowId) {
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId,
        method: "POST",
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/vnd.pingidentity.checkUsernamePassword+json'
        },
        data: {
            'username': email,
            'password': pass
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === 'VALIDATION_ERROR') {
                if (errData.details && errData.details.length >= 0 && errData.details[0].userMessage === 'authn.srvr.msg.account.locked') {
                    return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED, err));
                }
                return Promise.reject(getError('INVALID_CREDENTIALS', err));
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function getAuthCode(email, flowId) {
    let auth_code;
    return axios({
        url: pingHostURL + '/identity/api/authorization',
        method: 'post',
        withCredentials: true,
        data: {
            'username': email,
            'flowID': flowId
        }
    }).then(res => {
        const data = res.data;
        if (data && data.includes('name="error"')) {
            return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED, 'Account locked'));
        } else if (!data || !data.includes('name="code" value="')) {
            return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 4'));
        }
        auth_code = data.split('name="code" value="')[1].split('"/>')[0];
        return auth_code;
    })
}

function getAccessToken(auth_code, redirectURL, code_verifier, pingClientID) {
    return axios({
        url: pingHostURL + '/as/token.oauth2',
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify({
            grant_type: 'authorization_code',
            code: auth_code,
            redirect_uri: redirectURL,
            code_verifier: code_verifier,
            client_id: pingClientID
        })
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function authUserWithPing(email, pass) {
    let flowId;
    let code_verifier;
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const pingClientID = brand.webPFClient;
    let redirectURL = "https://" + window.location.hostname;
    if (sessionStorage.getItem(AppConstants.REDIRECT_URI)) redirectURL = sessionStorage.getItem(AppConstants.REDIRECT_URI);

    //let redirectURL = "https://" + window.location.hostname;
    // if (window.location.hostname.includes("local")) {
    //     redirectURL = 'https://boost-dev.dish.com';
    // }
    return getFlowID(redirectURL, pingClientID).then(res => {
        flowId = res.flowId;
        code_verifier = res.code_verifier;
        return initiateAuthSess(flowId);
    }).then(res => {
        return postCredentials(email, pass, flowId);
    }).then(res => {
        return getAuthCode(email, flowId);
    }).then(res => {
        //sessionStorage.setItem('auth_code', res);
        return getAccessToken(res, redirectURL, code_verifier, pingClientID);
    });
}

function getTillAuthCode(email, pass) {
    let flowId;
    let redirectURL = "https://" + window.location.hostname;
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const pingClientID = brand.webPFClient;
    if (window.location.hostname.includes("local")) {
        redirectURL = pingHostURL;
    }
    return getFlowID(redirectURL, pingClientID).then(res => {
        flowId = res.flowId;
        return initiateAuthSess(flowId);
    }).then(res => {
        return postCredentials(email, pass, flowId);
    }).then(res => {
        return getAuthCode(email, flowId);
    });
}

/*function postAccessTokenToMagento(access_token, cb) {
    const auth_code = sessionStorage.getItem('auth_code');
    return fetch(magentoHostURL + '/shop/ping/account/authenticate', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: qs.stringify({
            'access_token': access_token,
            'refresh_token': auth_code
        })
    }).then(res => {
        cb(res);
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function authUserAndPostToMag(email, pass, cb) {
    authUserWithPing(email, pass).then(res => {
        const data = res.data;
        if (!data && !('access_token' in data)) return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 5'));
        sessionStorage.setItem('access_token', data.access_token);
        return postAccessTokenToMagento(data.access_token, cb);
    }).catch(err => {
        cb(err);
    });
}*/

export {
    getError,
    authUserWithPing,
    getTillAuthCode,
    getAuthCode,
    getAccessToken
};