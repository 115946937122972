import axios from "axios";
import * as AppConstants from '../AppConstants';
import { getError } from "../api/PingIndentity";
import { generateRandomString, challenge_from_verifier } from '../Utitlity/PingCodeChallenge';
import { getAuthCode } from './PingIndentityPKCE'

const pingHostURL = process.env.REACT_APP_PING_HOST;

function getFlowID(redirectURL) {
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const name = sessionStorage.getItem(AppConstants.FIRSTNAME) || 'there';
    const cust_id = sessionStorage.getItem('accountNumber') || '';
    const customVarQueryStr = "&pi.template.variables.brand=" + brand.name + "&pi.template.variables.name=" + name + "&pi.template.variables.cust_id=" + cust_id;
    const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
    const pingClientID = isMobile ? brand.mfaMobClient : brand.mfaClient;
    let flowId;
    const code_verifier = generateRandomString().slice(0, 43);
    sessionStorage.setItem(AppConstants.CODE_VERIFIER, code_verifier);
    const code_challenge = challenge_from_verifier(code_verifier);
    return code_challenge.then(challenge => {
        return axios({
            url: pingHostURL + '/as/authorization.oauth2?client_id=' + pingClientID + '&redirect_uri=' + redirectURL + '&response_type=code&response_mode=form_post' + (!isMobile ? ('&code_challenge_method=S256&code_challenge=' + challenge) : '') + customVarQueryStr,
            method: 'get',
            withCredentials: true
        }).then(res => {
            const flowURLStr = res.request.responseURL;
            if (!flowURLStr || !flowURLStr.includes("flowId=")) {
                return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 1'));
            }
            flowId = flowURLStr.split("flowId=")[1];

            return Promise.resolve({
                code_verifier: code_verifier,
                flowId: flowId
            });

        }).catch(err => {
            return Promise.reject(getError('PING_ERROR', err));
        });
    });

}

function getCurrentState(flowId) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId,
        method: 'get',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate'
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function submitIdentifier(flowId, username) {
    if ((/^[0-9]{10}$/).test(username)) username = "+1" + username;
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=submitIdentifier',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'identifier': username
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function authenticate(flowId, checkAgain) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=authenticate',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        }
    }).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.AUTH_REQ_STATE && checkAgain) return authenticate(flowId, false);
        else return Promise.resolve(res);
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function continueAuthentication(flowId) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=continueAuthentication',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function setUpMFAAPI(flowId) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=setupMfa',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function selectDevicePairingMeth(flowId) {
    const userName = sessionStorage.getItem(AppConstants.USERNAME);
    let deviceType = "";
    if ((/^[0-9]{10}$/).test(userName)) deviceType = "SMS";
    else deviceType = "Email";
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=selectDevicePairingMethod',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            "devicePairingMethod": {
                "deviceType": deviceType
            }
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function submitEmailTarget(flowId) {
    const email = sessionStorage.getItem(AppConstants.USER_EMAIL)
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=submitEmailTarget',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            "email": email
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function submitSMSTarget(flowId) {
    const username = sessionStorage.getItem(AppConstants.USERNAME)
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=submitSmsTarget',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            "phone": "+1" + username
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function checkOTP(flowId, OTP) {

    return axios({
        url: pingHostURL + '/identity/api/authorization/code?action=checkOtp',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP,
            'flowID': flowId
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function checkOTPWithP1AS(deviceAuthID, OTP) {

    return axios({
        url: pingHostURL + '/identity/api/authorization/code',
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP,
            'deviceAuthID': deviceAuthID
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && (errData.code === AppConstants.PING_OTP_INVALID_ERROR || errData.code === 'INVALID_DATA')) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && (errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR || errData.code === 'REQUEST_FAILED')) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function checkOTPDirectPing(flowId, OTP) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=checkOtp',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function checkOTPTillAuthCode(username, flowId, OTP) {
    return checkOTPDirectPing(flowId, OTP).then(res =>
        continueAuthentication(flowId)
    ).then(res =>
        getAuthCode(username, flowId)
    );
}

function activateEmailDevice(flowId, OTP) {

    return axios({
        url: pingHostURL + '/identity/api/authorization/code?action=activateEmailDevice',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP,
            'flowID': flowId
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && (errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR || errData.code === AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR)) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function activateEmailDeviceDirectPing(flowId, OTP) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=activateEmailDevice',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && (errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR || errData.code === AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR)) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function activateEmailDeviceTillAuthCode(username, flowId, OTP) {
    return activateEmailDeviceDirectPing(flowId, OTP).then(res =>
        continueAuthentication(flowId)
    ).then(res =>
        getAuthCode(username, flowId)
    );
}

function activateSMSDevice(flowId, OTP) {

    return axios({
        url: pingHostURL + '/identity/api/authorization/code?action=activateSmsDevice',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP,
            'flowID': flowId
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && (errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR || errData.code === AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR)) {
                return Promise.reject(getError(AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function activateSMSDeviceDirectPing(flowId, OTP) {

    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId + '?action=activateSmsDevice',
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/json'
        },
        data: {
            'otp': OTP
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === AppConstants.PING_OTP_INVALID_ERROR) {
                return Promise.reject(getError(AppConstants.PING_OTP_INVALID_ERROR, err))
            } else if (errData.code && (errData.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR || errData.code === AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR)) {
                return Promise.reject(getError(AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR, err))
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });

}

function activateSMSDeviceTillAuthCode(username, flowId, OTP) {
    return activateSMSDeviceDirectPing(flowId, OTP).then(res =>
        continueAuthentication(flowId)
    ).then(res =>
        getAuthCode(username, flowId)
    );
}

function setUpMFAProc(flowId) {
    return setUpMFAAPI(flowId).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.DEVICE_PAIRING_STATE) return selectDevicePairingMeth(flowId);
        else return Promise.reject(getError('PING_ERROR', 'No valid status in the response'));
    }).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.EMAIL_TARGET_STATE) return submitEmailTarget(flowId);
        else if (data && data.status && data.status === AppConstants.SMS_TARGET_STATE) return submitSMSTarget(flowId);
        else return Promise.reject(getError('PING_ERROR', 'No valid status in the response'));
    }).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.EMAIL_ACTIVATE_STATE) {
            sessionStorage.setItem(AppConstants.MFA_VERIFY_STATE, AppConstants.EMAIL_ACTIVATE_STATE);
            return Promise.resolve(flowId)
        } else if (data && data.status && data.status === AppConstants.SMS_ACTIVATE_STATE) {
            sessionStorage.setItem(AppConstants.MFA_VERIFY_STATE, AppConstants.SMS_ACTIVATE_STATE);
            return Promise.resolve(flowId)
        } else return Promise.reject(getError('PING_ERROR', 'No valid status in the response'));
    });
}

function sendMFAWithP1AS(newEmail) {
    return axios({
        url: pingHostURL + '/identity/api/deviceAuthentications',
        method: 'POST',
        withCredentials: true,
        data: {
            'email': newEmail
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function verifyMFAWithPing(username) {
    let flowId;
    //let code_verifier;
    const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
    let redirectURL = !isMobile ? "https://" + window.location.hostname : sessionStorage.getItem(AppConstants.REDIRECT_URI);
    return getFlowID(redirectURL).then(res => {
        flowId = res.flowId;
        sessionStorage.setItem(AppConstants.FLOWID, flowId);
        //code_verifier = res.code_verifier;
        return getCurrentState(flowId);
    }).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.IDENTIFIER_REQ_STATE) return submitIdentifier(flowId, username);
        else return Promise.reject(getError('PING_ERROR', 'No valid status in the response'));
    }).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.AUTH_REQ_STATE) return authenticate(flowId, true);
        else return Promise.reject(getError('PING_ERROR', 'No valid status in the response'));
    }).then(res => {
        const data = res.data;
        if (data && data.status && data.status === AppConstants.OTP_REQ_STATE) {
            sessionStorage.setItem(AppConstants.MFA_VERIFY_STATE, AppConstants.OTP_REQ_STATE);
            return Promise.resolve(flowId);
        }
        else if (data && data.status && data.status === AppConstants.MFA_SETUP_STATE) return setUpMFAProc(flowId);
        else return Promise.reject(getError('PING_ERROR', 'No valid status in the response'));
    })
}



export {
    verifyMFAWithPing,
    activateEmailDevice,
    activateSMSDevice,
    checkOTP,
    checkOTPTillAuthCode,
    activateEmailDeviceTillAuthCode,
    activateSMSDeviceTillAuthCode,
    sendMFAWithP1AS,
    checkOTPWithP1AS
};