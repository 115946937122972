import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import EmailSignIn from './components/email-signin/EmailSignin';
import EmailUpdate from './components/EmailUpdate/EmailUpdate';
import EmailUpdateSuccess from './components/EmailUpdateSuccess/EmailUpdateSuccess';
import PasswordSignIn from './components/PasswordSignin/PasswordSignin';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import SetPassword from './components/SetPassword/SetPassword';
import MFAComp from './components/MFAComp/MFAComp';
import OTPSignin from './components/OTPSignin/OTPSignin'
import { Helmet } from 'react-helmet-async';
import * as AppConstants from './AppConstants';

function App() {

    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const analyticsTag = process.env.REACT_APP_ADOBE_ANALY_TAG;
    const queryParams = new URLSearchParams(window.location.search);
    const actionParam = queryParams.get('action');
    const updateEmail = actionParam && actionParam === 'updateEmail';
    return (
        <Fragment>
            <Helmet>
                {brand.name === AppConstants.BOOST_BRAND ? <script type="text/javascript" prioritizeSeoTags>
                    window.streamID = "07d024bb-8ace-468b-98bf-3090ae7f0759";
                    window.dStream = "07d024bb-8ace-468b-98bf-3090ae7f0759:dev";
                    window.sStream = "07d024bb-8ace-468b-98bf-3090ae7f0759:stage";
                    window.pStream = "07d024bb-8ace-468b-98bf-3090ae7f0759:prod";
                </script> : null}
                {brand.name === AppConstants.GENESIS_BRAND ? <script type="text/javascript" prioritizeSeoTags>
                    window.streamID = "b9b1b3a2-4ccc-4972-b872-311ccf6320b2";
                    window.dStream = "b9b1b3a2-4ccc-4972-b872-311ccf6320b2:dev";
                    window.sStream = "b9b1b3a2-4ccc-4972-b872-311ccf6320b2:stage";
                    window.pStream = "b9b1b3a2-4ccc-4972-b872-311ccf6320b2:prod";
                </script> : null}
                {brand.name === AppConstants.INFINITE_BRAND ? <script type="text/javascript" prioritizeSeoTags>
                    window.dStream = "55e92198-a195-4654-a191-2aea9497888b";
                    window.sStream = "5d24c560-bb64-48ce-8211-a2e869af75cd";
                    window.pStream = "7a1a44f8-1309-4d5c-81c0-f536b290c663";
                </script> : null}
                <script src={analyticsTag} async></script>
            </Helmet>
            <Switch>                                
                <Route exact path="/" component={updateEmail ? EmailUpdate : EmailSignIn} />
                <Route exact path="/password" component={PasswordSignIn} />
                <Route exact path="/otp/signin" component={OTPSignin} />
                <Route exact path="/forgot" component={ForgotPassword} />
                <Route exact path="/setPassword" component={SetPassword} />
                <Route exact path="/mfa" component={MFAComp} />
                <Route exact path="/email/success" component={EmailUpdateSuccess} />
            </Switch>
        </Fragment>
    );
}

export default App;
