import { useEffect, useRef, useState, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import { patchNewEmail, patchStatus } from './../../api/auth';
import HeaderComp from '../HeaderComp/HeaderComp';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import slideTransition from "../CssTransitions/slide.module.css";
import slideExitTransition from "../CssTransitions/slide-exit.module.css";
import * as AppConstants from '../../AppConstants';
import * as BoostPageContents from '../../Boost/PageContents';
import * as GenesisPageContents from '../../Genesis/PageContents';
import * as InfinitePageContents from '../../Infinite/PageContents';
import ModalComp from "../ModalComp/ModalComp";
import boostStyles from './BoostMFAComp.module.css';
import genesisStyles from './GenesisMFAComp.module.css';
import infiniteStyles from './InfiniteMFAComp.module.css';
import { checkOTP, verifyMFAWithPing, activateEmailDevice, activateSMSDevice, sendMFAWithP1AS, checkOTPWithP1AS } from '../../api/PingIdentityMFA';
import { sendFormErrEvent, sendClickEvent } from '../../Utitlity/AdobeAnalytics';
import { testEmail } from '../../Utitlity/Misc';

function MFAComp() {
    const [show, setShow] = useState(false);
    const [transitionTo, setTransitionTo] = useState('Continue');
    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    const linkRef = useRef();
    const nodeRef = useRef();
    const inputContainerRef = useRef();
    const history = useHistory();
    const redirectURI = sessionStorage.getItem(AppConstants.REDIRECT_URI) ? "?redirect_uri=" + sessionStorage.getItem(AppConstants.REDIRECT_URI) : "";
    const [loading, setLoading] = useState(false);
    const [loadModal, setLoadModal] = useState(false);
    const [isResendCode, setIsResendCode] = useState(false);
    const [error, setError] = useState('');
    const [otpErrorCount, setOTPErrorCount] = useState(0);
    //const email = sessionStorage.getItem(AppConstants.USER_EMAIL);
    const userName = sessionStorage.getItem(AppConstants.USERNAME);
    const userNameFormatted = ((/^[0-9]{10}$/).test(userName)) ? getNumberFormat(userName) : userName.toLowerCase();
    const isEmailUpdate = sessionStorage.getItem(AppConstants.ACTION) && sessionStorage.getItem(AppConstants.ACTION) === AppConstants.UPDATE_EMAIL ? true : false;


    let styles;
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    let PageContents;
    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            PageContents = BoostPageContents;
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            PageContents = GenesisPageContents;
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            PageContents = InfinitePageContents;
            styles = infiniteStyles;
            break;
        default:
            PageContents = BoostPageContents;
            styles = boostStyles;
    }

    let otpErrorMsg, mfaPrimText, mfaSecText;

    if (testEmail(userName)) {
        otpErrorMsg = AppConstants.EMAIL_OTP_ISSUE(e => {
            e.preventDefault();
            setTransitionTo('Back');
            setShow(false);
        });
        mfaPrimText = PageContents.MFA_PRIM_TEXT_EMAIL;
        mfaSecText = PageContents.MFA_SEC_TEXT_EMAIL(userNameFormatted);
    } else {
        otpErrorMsg = AppConstants.SMS_OTP_ISSUE(e => {
            e.preventDefault();
            setTransitionTo('Back');
            setShow(false);
        });
        mfaPrimText = PageContents.MFA_PRIM_TEXT_PHONE;
        mfaSecText = PageContents.MFA_SEC_TEXT_PHONE(userNameFormatted);
    }

    useEffect(() => {
        window.adobeDataLayer = window.adobeDataLayer || [];
        const adobeObj = {
            event: 'screen_load',
            web: {
                webPageDetails: {
                    pageViews: {
                        value: 1
                    },
                    name: 'BMWeb|ping-onetimecode' + (isEmailUpdate ? '-update-email' : ''),
                    domain: brand.name,
                    language: 'en',
                    siteSection: 'onetimecode',
                    siteSubSection: 'onetimecode',
                    siteSubSubSection: 'onetimecode',
                    URL: window.location.href,
                    qsp: window.location.search,
                    platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
                    type: 'login onetimecode page'

                }
            }

        }
        window.adobeDataLayer.push(adobeObj);
    }, [brand.name, isEmailUpdate]);

    useEffect(() => {
        if (loadModal && buttonRef && linkRef && buttonRef.current && linkRef.current) {
            buttonRef.current.disabled = true;
            linkRef.current.classList.add("disabled");
            containerRef.current.classList.add("container-main--loading");
        } else if (!loadModal && !loading && buttonRef && linkRef && buttonRef.current && linkRef.current) {
            buttonRef.current.disabled = false;
            linkRef.current.classList.remove("disabled");
            containerRef.current.classList.remove("container-main--loading");
        }
    }, [loadModal, loading]);

    function onBackClick(e) {
        e.preventDefault();
        setTransitionTo('Back');
        setLoadModal(false);
        setShow(false);
    }

    function enableLoadingStyle() {
        buttonRef.current.disabled = true;
        document.querySelectorAll("input").forEach((input) => {
            input.disabled = true;
        });
        linkRef.current.classList.add("disabled");
        setLoading(true);
        containerRef.current.classList.add("container-main--loading");
    }

    function disableLoadingStyle() {
        buttonRef.current.disabled = false;
        document.querySelectorAll("input").forEach((input) => {
            input.disabled = false;
        });
        linkRef.current.classList.remove("disabled");
        setLoading(false);
        containerRef.current.classList.remove("container-main--loading");
    }

    function sendOTPWithPing(e) {
        e.preventDefault();
        if (isEmailUpdate) sendClickEvent("email update otp resend", "email-update-otp-resend", window.location.href, "BMWeb|Update Email OTP");
        else sendClickEvent("Ping OTP Resend", "ping-otp-resend", window.location.href, "BMWeb|Ping OTP Page");
        setError(false);
        setIsResendCode(false);
        setLoadModal(true);
    }

    function sendMFACode(e) {
        e.preventDefault();
        setError(false);
        setIsResendCode(false);
        enableLoadingStyle();
        setLoadModal(false);
        if (inputContainerRef && inputContainerRef.current) {
            const childrenElems = inputContainerRef.current.children;
            let i = 0;
            while (i < 6) {
                const elem = childrenElems[i];
                elem.value = "";
                i++;
            }

        }

        if(process.env.REACT_APP_ENABLE_OTP_P1AS){
            return sendMFAWithP1AS(userName).then(res => {
                sessionStorage.setItem(AppConstants.DEVICE_AUTHENTICATION_ID, res?.data?.id);
                disableLoadingStyle();
                setIsResendCode(true);
            }).catch(err => {
                disableLoadingStyle();
                const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                sendFormErrEvent('Ping: MFAForm', 'PingMFAPage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to resend one time code');
                setError(AppConstants.SERVER_ISSUE);
            });
        } else {
            return verifyMFAWithPing(userName).then(res => {
                disableLoadingStyle();
                setIsResendCode(true);
            }).catch(err => {
                disableLoadingStyle();
                const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                sendFormErrEvent('Ping: MFAForm', 'PingMFAPage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to resend one time code');
                setError(AppConstants.SERVER_ISSUE);
            });
        }
    }

    function getNumberFormat(userName) {
        return userName.substr(0, 3) + "-" + userName.substr(3, 3) + "-" + userName.substr(6, 4);
    }

    function submitMFA(e) {
        e.preventDefault();
        if (isEmailUpdate) sendClickEvent("email update otp continue", "email-update-otp-continue", window.location.href, "BMWeb|Update Email OTP");
        else sendClickEvent("Ping OTP Continue", "ping-otp-continue", window.location.href, "BMWeb|Ping OTP Page");
        setError(false);
        setIsResendCode(false);
        enableLoadingStyle();
        const flowId = sessionStorage.getItem(AppConstants.FLOWID) || sessionStorage.getItem(AppConstants.DEVICE_AUTHENTICATION_ID);
        let otpEntered = "";
        if (inputContainerRef && inputContainerRef.current) {
            const childrenElems = inputContainerRef.current.children;
            let i = 0;
            while (i < 6) {
                const elem = childrenElems[i];
                if (elem.value.length !== 1) {
                    disableLoadingStyle();
                    return setError(AppConstants.REGEX_OTP_FAIL);
                }
                otpEntered += elem.value;
                i++;
            }

        }
        if (flowId || process.env.REACT_APP_ENABLE_OTP_P1AS) {
            const mfaState = sessionStorage.getItem(AppConstants.MFA_VERIFY_STATE);
            const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
            let mfaAPICall = checkOTP;
            if (mfaState && mfaState === AppConstants.OTP_REQ_STATE) {
                mfaAPICall = checkOTP;
            } else if (mfaState && mfaState === AppConstants.EMAIL_ACTIVATE_STATE) {
                mfaAPICall = activateEmailDevice;
            } else if (mfaState && mfaState === AppConstants.SMS_ACTIVATE_STATE) {
                mfaAPICall = activateSMSDevice;
            }else if (process.env.REACT_APP_ENABLE_OTP_P1AS) {
                mfaAPICall = checkOTPWithP1AS;
            }
            return mfaAPICall(flowId, otpEntered).then(res => {
                if (res && res.data && res.data.status === AppConstants.MFA_FAILED) {
                    disableLoadingStyle();
                    setOTPErrorCount(otpErrorCount + 1);
                    if (otpErrorCount > 0 && !isEmailUpdate) return setError(otpErrorMsg);
                    sendFormErrEvent('Ping: MFAForm', 'PingMFAPage' + (isMobile ? '_Mob: ' : ': ') + 'Entered incorrect one time code');
                    return setError(isEmailUpdate ? PageContents.INVALID_OTP_UPDATE_EMAIL : PageContents.INVALID_OTP);
                }

                if (isEmailUpdate) {
                    patchNewEmail(userName).then(res => {
                        disableLoadingStyle();
                        setShow(false);
                    }).catch(res => {
                        disableLoadingStyle();
                        let errMsg = res.error.code;
                        if (errMsg === AppConstants.EMAIL_EXISTS) errMsg = PageContents.EMAIL_EXISTS;
                        sendFormErrEvent('PingNewEmail: VerifyOTPPage', 'PatchNewEmailFailed' + (isMobile ? '_Mob: ' : ': ') + errMsg);
                        setError(errMsg);
                    });
                } else {
                    const accNo = sessionStorage.getItem('accountNumber');
                    patchStatus(userName, accNo).then(res => {
                        disableLoadingStyle();
                        setShow(false);
                    }).catch(res => {
                        disableLoadingStyle();
                        sendFormErrEvent('Ping: MFAForm', 'PatchStatusFailed' + (isMobile ? '_Mob: ' : ': ') + res.error.code);
                        setShow(false);
                    });
                }
            }).catch(err => {
                disableLoadingStyle();
                setOTPErrorCount(otpErrorCount + 1);
                if (otpErrorCount > 0 && !isEmailUpdate) return setError(otpErrorMsg);
                if (err && err.error && err.error.code === AppConstants.PING_OTP_INVALID_ERROR) {
                    sendFormErrEvent('Ping: MFAForm', 'PingMFAPage' + (isMobile ? '_Mob: ' : ': ') + 'Entered incorrect one time code');
                    return setError(isEmailUpdate ? PageContents.INVALID_OTP_UPDATE_EMAIL : PageContents.INVALID_OTP);
                } else if (err && err.error && (err.error.code === AppConstants.PING_OTP_INVALID_LIMIT_ERROR || err.error.code === AppConstants.PING_SMS_OTP_INVALID_LIMIT_ERROR)) {
                    sendFormErrEvent('Ping: MFAForm', 'PingMFAPage' + (isMobile ? '_Mob: ' : ': ') + 'Exceeded incorrect one time code attempts');
                    return setError(AppConstants.INVALID_OTP_LIMIT_MSG);
                }
                sendFormErrEvent('Ping: MFAForm', 'PingMFAPage' + (isMobile ? '_Mob: ' : ': ') + 'Server issue while verifying the one time code');
                setError(AppConstants.SERVER_ISSUE);
            });
        }
        disableLoadingStyle();
    }

    function onCodeInput(e) {
        const elem = e.target;
        const elemVal = elem.value;
        const nextElem = elem.nextElementSibling;
        if (elemVal.length > elem.maxLength) {
            elem.value = elemVal.substring(0, 1);
            if (nextElem) {
                nextElem.focus();
                nextElem.value = elemVal.substring(1);
                nextElem.dispatchEvent(new Event('input', { bubbles: true }));
            } else {
                elem.blur();
                buttonRef.current.click();
            }
        } else if (nextElem) {
            nextElem.focus();
        } else {
            elem.blur();
            buttonRef.current.click();
        }

    }

    function onkeydown(e) {
        const elem = e.target;
        const elemVal = elem.value;
        const prevElem = elem.previousElementSibling;
        if ([8, 46].includes(e.keyCode) && elemVal.length === 0 && prevElem) {
            prevElem.focus();
        }
    }

    function onfocus(e) {
        e.target.value = "";
    }

    useEffect(() => {
        setShow(prevState => !prevState);
    }, []);

    return (
        <div className="container-main flex flex-center-all">
            <ModalComp primText={AppConstants.MFA_MODAL_PRIM_TEXT} showComp={loadModal} closeModal={e => setLoadModal(false)}
                secText={<Fragment>A temporary code will be sent to <strong> {userNameFormatted} </strong>{isEmailUpdate ? " to update your email" : " to create your password"} </Fragment>}
                btnText={AppConstants.FRGT_PASS_MODAL_BTN_TXT}
                sendCode={sendMFACode}
                brandName={brand.name} />
            {isEmailUpdate && brand.name === AppConstants.INFINITE_BRAND ? null : <HeaderComp isBackBtnReq={brand.name === AppConstants.BOOST_BRAND ? "true" : "false"} redirectURI={"/" + redirectURI} loading={loading} onClickCallback={onBackClick} />}
            <CSSTransition nodeRef={nodeRef} in={show} timeout={200} classNames={transitionTo === 'Back' ? slideTransition : slideExitTransition} onExited={() => {
                if (transitionTo === 'Back') {
                    if (isEmailUpdate) sendClickEvent("email update otp back", "email-update-otp-back", window.location.href, "BMWeb|Update Email OTP");
                    else sendClickEvent("Ping OTP Back", "ping-otp-back", window.location.href, "BMWeb|Ping OTP Page");
                    history.push("/" + redirectURI);
                }
                else if (isEmailUpdate) history.push('/email/success')
                else history.push('/setPassword');
            }} mountOnEnter unmountOnExit>
                <div className="identity-body flex flex-center-all" ref={nodeRef} >
                    <div className="container-load flex">
                        <form ref={containerRef} className="email-box flex" style={{ justifyContent: 'center' }}>
                            {
                                brand.name !== AppConstants.GENESIS_BRAND && !!error ?
                                    <ErrorBlock message={error} />
                                    :
                                    null
                            }

                            {
                                brand.name !== AppConstants.BOOST_BRAND && isResendCode === true ?
                                    <ErrorBlock message="We've sent you a new code. Please try again." isInfo={true} />
                                    :
                                    null
                            }

                            <h1 className="primary-text">
                                {isEmailUpdate ? PageContents.EMAIL_VERIFY_PRIM_TEXT : mfaPrimText}
                            </h1>

                            <div className='prof-container transparent' style={{ marginTop: '6px', marginBottom: '0px' }}>
                                <p className="secondary-text" style={{ lineHeight: '21px', maxWidth: '100%', overflowWrap: 'break-word' }}>{mfaSecText}</p>
                            </div>

                            <div className={styles['code-container']} ref={inputContainerRef}>
                                <input className={error && !!error ? styles['code-box'] + " " + styles['error'] : styles['code-box']} id="input-1" type="number" inputMode="numeric" size="1" pattern="\d*" maxLength="1" onInput={onCodeInput} onKeyDown={onkeydown} onFocus={onfocus} autoFocus />
                                <input className={error && !!error ? styles['code-box'] + " " + styles['error'] : styles['code-box']} id="input-2" type="number" inputMode="numeric" size="1" pattern="\d*" maxLength="1" onInput={onCodeInput} onKeyDown={onkeydown} onFocus={onfocus} />
                                <input className={error && !!error ? styles['code-box'] + " " + styles['error'] : styles['code-box']} id="input-3" type="number" inputMode="numeric" size="1" pattern="\d*" maxLength="1" onInput={onCodeInput} onKeyDown={onkeydown} onFocus={onfocus} />
                                <input className={error && !!error ? styles['code-box'] + " " + styles['error'] : styles['code-box']} id="input-4" type="number" inputMode="numeric" size="1" pattern="\d*" maxLength="1" onInput={onCodeInput} onKeyDown={onkeydown} onFocus={onfocus} />
                                <input className={error && !!error ? styles['code-box'] + " " + styles['error'] : styles['code-box']} id="input-5" type="number" inputMode="numeric" size="1" pattern="\d*" maxLength="1" onInput={onCodeInput} onKeyDown={onkeydown} onFocus={onfocus} />
                                <input className={error && !!error ? styles['code-box'] + " " + styles['error'] : styles['code-box']} id="input-6" type="number" inputMode="numeric" size="1" pattern="\d*" maxLength="1" onInput={onCodeInput} onKeyDown={onkeydown} onFocus={onfocus} />
                            </div>

                            {
                                brand.name === AppConstants.GENESIS_BRAND && !!error ?
                                    <p className="err-block" style={{ maxWidth: '392px', textAlign: 'justify' }}>{error}</p>
                                    :
                                    null
                            }

                            <button type="submit" ref={buttonRef} onClick={submitMFA} className="modal--button--primary primary" >{PageContents.MFA_BTN_TEXT}</button>
                        </form>
                    </div>
                    <Link to="#" ref={linkRef} className="missing-footer" onClick={sendOTPWithPing} >{PageContents.RESEND_OTP}</Link>
                </div>
            </CSSTransition >
        </div>

    );
}

export default MFAComp;