import * as AppConstants from '../AppConstants';

function Brand(name, magentoHost, agreementName, loginDomain, webPFClient, mobPFClient, mfaClient, mfaMobClient) {
    this.name = name;
    this.magentoHost = magentoHost;
    this.agreementName = agreementName;
    this.loginDomain = loginDomain;
    this.webPFClient = webPFClient;
    this.mobPFClient = mobPFClient;
    this.mfaClient = mfaClient;
    this.mfaMobClient = mfaMobClient;
}


const boostMagentoHost = process.env.REACT_APP_BOOST_MAJENTO_HOST;
const genesisMagentoHost = process.env.REACT_APP_GENESIS_MAJENTO_HOST;
const infiniteMagentoHost = process.env.REACT_APP_INFINITE_MAJENTO_HOST;
const boostWebPFClient = process.env.REACT_APP_PING_BOOST_WEB_CLIENT_ID;
const boostMobPFClient = process.env.REACT_APP_PING_BOOST_MOB_CLIENT_ID;
const boostMFAClient = process.env.REACT_APP_PING_BOOST_MFA_CLIENT_ID;
const boostMFAMobClient = process.env.REACT_APP_PING_BOOST_MFA_MOB_CLIENT_ID;
const genesisWebPFClient = process.env.REACT_APP_PING_GENESIS_WEB_CLIENT_ID;
const genesisMobPFClient = process.env.REACT_APP_PING_GENESIS_MOB_CLIENT_ID;
const genesisMFAClient = process.env.REACT_APP_PING_GENESIS_MFA_CLIENT_ID;
const genesisMFAMobClient = process.env.REACT_APP_PING_GENESIS_MFA_MOB_CLIENT_ID;
const infiniteWebPFClient = process.env.REACT_APP_PING_INFINITE_WEB_CLIENT_ID;
const infiniteMobPFClient = process.env.REACT_APP_PING_INFINITE_MOB_CLIENT_ID;
const infiniteMFAClient = process.env.REACT_APP_PING_INFINITE_MFA_CLIENT_ID;
const infiniteMFAMobClient = process.env.REACT_APP_PING_INFINITE_MFA_MOB_CLIENT_ID;

const brandData = [
    new Brand(AppConstants.INFINITE_BRAND, infiniteMagentoHost, ['RW_INFINITE', 'RW-INFINITE'], 'RW-INFINITE', infiniteWebPFClient, infiniteMobPFClient, infiniteMFAClient, infiniteMFAMobClient),
    new Brand(AppConstants.BOOST_BRAND, boostMagentoHost, ['RW_BOOST', 'RW-BOOST'], 'RW-BOOSTMOBILE', boostWebPFClient, boostMobPFClient, boostMFAClient, boostMFAMobClient),
    new Brand(AppConstants.GENESIS_BRAND, genesisMagentoHost, ['RW_GENESIS', 'RW-GENESIS'], 'GENESIS', genesisWebPFClient, genesisMobPFClient, genesisMFAClient, genesisMFAMobClient)
];

export default brandData;