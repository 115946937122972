import axios from "axios";
import { getError } from "../api/PingIndentity";
import * as AppConstants from '../AppConstants';
import { generateApigeeCorrID } from '../Utitlity/PingCodeChallenge';
import { testEmail } from '../Utitlity/Misc';

let corrID = generateApigeeCorrID();
let msgID = 1000;
let clientID = 'RW_BOOST';
const pingHostURL = process.env.REACT_APP_PING_HOST;

function fixedEncodeURIComponent(str) {

    if (!testEmail(str)) {
        const username = str.replace(/\D/g, '')
        sessionStorage.setItem(AppConstants.USERNAME, username);
        return username;
    }
    sessionStorage.setItem(AppConstants.USERNAME, str);
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}


function submitEmail(payload, isUpdateEmailReq, token, cb) {
    msgID = msgID + 1;
    let agreementName = JSON.parse(sessionStorage.getItem('brand')).agreementName[1];
    axios({
        url: pingHostURL + "/identity/api/customer",
        method: "post",
        headers: {
            'X-Correlation-Id': corrID,
            'X-Message-Id': msgID,
            'X-Client-Id': clientID,
            'X-TimeStamp': (new Date()).toISOString(),
            'X-ForwardedBy': clientID
        },
        data: {
            'token': token,
            'userName': fixedEncodeURIComponent(payload),
            'domain': agreementName,
            'isUpdateEmailReq': isUpdateEmailReq
        }
    }).then(function (res) {
        if (res.data && res.data.isError === true) {
            return cb('server-error');
        }
        cb(res);
    }).catch(function (err) {
        cb('server-error');
    });

}

function patchNewPassword(pass, accNo) {
    msgID = msgID + 1;
    let loginDomain = JSON.parse(sessionStorage.getItem('brand')).loginDomain;
    return axios({
        url: pingHostURL + '/identity/api/customer/' + accNo + '/credentials/v2',
        method: "PATCH",
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'X-Correlation-Id': corrID,
            'X-Message-Id': msgID,
            'X-Client-Id': clientID,
            'X-TimeStamp': (new Date()).toISOString(),
            'X-ForwardedBy': clientID
        },
        data: {
            'domain': loginDomain,
            'password': pass,
            'passwordChangeRequired': false
        }
    }).catch(err => {
        if (err.response && err.response.status && err.response.status === 400) return Promise.reject(getError(AppConstants.LIMIT_EXCEEDED_MESSAGE, err));
        //else if (err.response && err.response.status && err.response.status === 423) return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED_MSG, err));
        return Promise.reject(getError(AppConstants.SERVER_ISSUE, err));
    });
}

function patchStatus(userName, accNo) {
    msgID = msgID + 1;
    let loginDomain = JSON.parse(sessionStorage.getItem('brand')).loginDomain;
    return axios({
        url: pingHostURL + '/identity/api/customer/' + accNo + '/credentials/v2',
        method: "PATCH",
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'X-Correlation-Id': corrID,
            'X-Message-Id': msgID,
            'X-Client-Id': clientID,
            'X-TimeStamp': (new Date()).toISOString(),
            'X-ForwardedBy': clientID
        },
        data: {
            'domain': loginDomain,
            'userName': userName,
            'status': 'ACTIVE'
        }
    }).catch(err => {
        if (err.response && err.response.status && err.response.status === 400) return Promise.reject(getError(AppConstants.SERVER_ISSUE, err));
        //else if (err.response && err.response.status && err.response.status === 423) return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED_MSG, err));
        return Promise.reject(getError(AppConstants.SERVER_ISSUE, err));
    });
}

function patchNewEmail(email) {
    msgID = msgID + 1;
    return axios({
        url: pingHostURL + '/identity/api/customer/email',
        method: "PATCH",
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'X-Correlation-Id': corrID,
            'X-Message-Id': msgID,
            'X-Client-Id': clientID,
            'X-TimeStamp': (new Date()).toISOString(),
            'X-ForwardedBy': clientID
        },
        data: {
            'email': email
        }
    }).catch(err => {
        if (err.response && err.response.status && err.response.status === 400) return Promise.reject(getError(AppConstants.EMAIL_EXISTS, err));
        //else if (err.response && err.response.status && err.response.status === 423) return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED_MSG, err));
        return Promise.reject(getError(AppConstants.SERVER_ISSUE, err));
    });
}

function patchPasswordFlag(accNo) {
    msgID = msgID + 1;
    let loginDomain = JSON.parse(sessionStorage.getItem('brand')).loginDomain;
    return axios({
        url: pingHostURL + '/identity/api/customer/' + accNo + '/credentials/v2',
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
            'X-Correlation-Id': corrID,
            'X-Message-Id': msgID,
            'X-Client-Id': clientID,
            'X-TimeStamp': (new Date()).toISOString(),
            'X-ForwardedBy': clientID
        },
        data: {
            'domain': loginDomain,
            'passwordChangeRequired': true
        }
    }).catch(err => {
        return Promise.reject(getError(AppConstants.SERVER_ISSUE, err));
    });
}

/*function sendTempCode(accNo, username) {
    msgID = msgID + 1;
    let agreementName = JSON.parse(sessionStorage.getItem('brand')).agreementName[1];
    return axios({
        url: apigeeHostURL + '/wireless/retail/v1/customer-management-service/customer/' + accNo + '/resetPassword',
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'X-Correlation-Id': corrID,
            'X-Message-Id': msgID,
            'X-Client-Id': clientID,
            'X-TimeStamp': (new Date()).toISOString(),
            'X-ForwardedBy': clientID
        },
        data: {
            'userName': username,
            'domain': agreementName,
            'validUserName': true
        }
    }).catch(err => {
        if (err.response && err.response.status && err.response.status === 500) return Promise.reject(getError(AppConstants.PASS_HISTORY_MSG, err));
        else if (err.response && err.response.status && err.response.status === 400) return Promise.reject(getError(AppConstants.LIMIT_EXCEEDED_MESSAGE, err));
        return Promise.reject(getError(AppConstants.SERVER_ISSUE, err));
    });
}*/

export {
    submitEmail,
    patchNewPassword,
    patchPasswordFlag,
    patchNewEmail,
    patchStatus
};