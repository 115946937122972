export const INVALID_EMAIL = "We couldn't find your Boost Infinite Account. Please use the email address associated with your order.";
export const EMAIL_INPUT_LABEL = 'Email';
export const HOME_PRIM_TEXT = 'Login';
export const PASS_PRIM_TEXT = 'Hi, ';
export const PASS_SEC_TEXT = 'To continue, first verify it’s you';
export const FORGOT_PRIM_TEXT = 'Verify Identity';
export const MFA_PRIM_TEXT_EMAIL = 'Verify Your Email';
export const MFA_PRIM_TEXT_PHONE = 'Verify Your Phone';
export const MFA_SEC_TEXT_EMAIL = (username => "An email with your one time passcode was just sent to " + username);
export const MFA_SEC_TEXT_PHONE = (username => "A message with your one time passcode was just sent to " + username);
export const EMAIL_VERIFY_PRIM_TEXT = 'Verify Your Email';
export const UPDATE_EMAIL_PRIM_TEXT = 'Enter New Email';
export const SET_PASS_PRIM_TEXT = 'Create New Password';
export const HOME_SEC_TEXT = "Login to your Boost Infinite account using the email associated with your account.";
export const FORGOT_SEC_TEXT = 'We emailed your temporary password to ';
export const SET_PASS_SEC_TEXT = "Password must contain 8+ characters with a mix of letters, numbers & symbols";
export const UPDATE_EMAIL_SEC_TEXT = "We'll send you a verification code to confirm the new email.";
export const PASS_INPUT_LABEL = 'Password';
export const FORGOT_PASS_INPUT_LABEL = 'Temporary Password';
export const CREATE_PASS_LABEL = 'New Password';
export const CNFRM_PASS_LABEL = 'Confirm Password';
export const BTN_TEXT = 'Continue';
export const PASS_BTN_TEXT = 'Sign In';
export const MFA_BTN_TEXT = 'Verify';
export const CREATE_PASS_BTN_TEXT = 'Create Password';
export const DIDNT_CODE = "Didn't receive temporary password?";
export const VERIFY_WITH_OTP = "Can't find your temp password?";
export const RESEND_OTP = "Send a New Code";
export const OTP_USE_PASS = "Sign In With Password";
export const PASS_EMAIL_CODE = "Sign In With a Code Instead";
export const PASS_SMS_CODE = "Sign In With a Code Instead";
export const PASS_MATCH_ERR = "Passwords don’t match. Try again.";
export const INVALID_PASSWORD = 'Password is incorrect. Try again.';
export const INVALID_OTP = 'The code is wrong. Try again.';
export const INVALID_OTP_UPDATE_EMAIL = 'The code is wrong. Check your email again.';
export const EMAIL_EXISTS = 'Email already in use for an Infinite account.';