import boostStyles from './BoostPassComp.module.css';
import genesisStyles from './GenesisPassComp.module.css';
import infiniteStyles from './InfinitePassComp.module.css';
import { useEffect, useRef, useState } from 'react';
import * as AppConstants from '../../AppConstants';
import PassIcon from '../Icons/PassIcon/PassIcon';

function PassComp(props) {
    const [toggle, setToggle] = useState(false);
    const [focussed, setFocussed] = useState(false);
    //const [pass, setPass] = useState("");
    const [passHidden, setPassHidden] = useState("");
    const [timeoutFunc, setTimeoutFunc] = useState(null);
    const [labelClass, setLabelClass] = useState('prim-label-hidden');
    const inputRef = useRef();
    const labelRef = useRef();
    const passHiddenRef = useRef();
    const passIconRef = useRef();
    const shadowRef = useRef();

    useEffect(() => {
        /*let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            !window.MSStream */
        if (props.isFocussed.includes("true")) setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
        }, 1000);
    }, [inputRef, props.isFocussed]);

    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    let styles;

    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }

    //const inputChangeFunc = props.onInputChange;
    const isDisabled = props.isDisabled && props.isDisabled === true ? true : false;
    const type = (props.type && props.type === 'email') ? 'email' : (passHidden.length !== 0) ? 'text' : 'password';
    if (type === 'password' && inputRef.current && passHiddenRef.current) {
        inputRef.current.type = type;
        inputRef.current.name = "password";
        inputRef.current.autocomplete = "current-password";
        passHiddenRef.current.type = 'text';
    } else if (type !== 'email' && inputRef.current && passHiddenRef.current && passHiddenRef.current.type !== 'password') {
        inputRef.current.type = 'text';
        inputRef.current.name = "";
        inputRef.current.autocomplete = "";
        passHiddenRef.current.type = 'password';
        passHiddenRef.current.name = "password";
        passHiddenRef.current.autocomplete = "current-password";
    }

    const rectShadClass = (props.error && !!props.error) ? styles['rectangle-shadow-error'] : (focussed === false ? styles['rectangle-shadow'] : styles['rectangle-shadow'] + " " + styles['rectangle-shadow-active']);
    const inpContClass = brand.name === AppConstants.BOOST_BRAND ? styles['input-container'] : ((props.error && !!props.error) ? styles['input-container'] + " " + styles['input-container-error'] : (focussed === false ? styles['input-container'] : styles['input-container'] + " " + styles['input-container-active']));
    const pasteDisable = props.pasteEnable && props.pasteEnable.includes('false');

    //If disabled is true then pass is submitted. change input type to password for autofill. Make the pass icon to close if it is open.
    if (isDisabled && inputRef.current && type !== 'email' && passIconRef.current) {
        clearTimeout(timeoutFunc);
        inputRef.current.value = inputRef.current.value.replace(/./g, "•");
        if (!!toggle) passIconRef.current.click();
    }

    useEffect(() => {
        if (!focussed && inputRef && inputRef.current && inputRef.current.value.length === 0) {
            inputRef.current.placeholder = props.label;
            setLabelClass('prim-label-hidden');
        }
        if (focussed && inputRef && inputRef.current) {
            setLabelClass('prim-label');
            inputRef.current.placeholder = "";
        }

    }, [focussed, props.label]);

    function onInpChange(e) {
        e.preventDefault();
        if (type === 'email') {
            inputRef.current.value = e.target.value;
            return props.onInputChange(e);
        }
        inputRef.current.type = "text";
        const passwordVal = e.target.value;
        const cursorLoc = e.target.selectionStart;

        if (!toggle) {
            let showLength = 1;
            const hiddenPassVal = passHidden;
            let offset = passwordVal.length - hiddenPassVal.length;
            let newPassHidden = "";

            if (offset > 0) {
                newPassHidden = hiddenPassVal +
                    passwordVal.substring(
                        hiddenPassVal.length,
                        hiddenPassVal.length + offset
                    )
                //If the new character is somewhere in the middle
                if (cursorLoc !== passwordVal.length) {
                    newPassHidden = hiddenPassVal.substring(0, cursorLoc - 1) + passwordVal.charAt(cursorLoc - 1) + hiddenPassVal.substring(cursorLoc - 1, hiddenPassVal.length);
                    inputRef.current.value = passwordVal
                        .substring(0, cursorLoc - 1)
                        .replace(/./g, "•") +
                        passwordVal.charAt(cursorLoc - 1) + passwordVal.substring(cursorLoc - 1, hiddenPassVal.length).replace(/./g, "•");
                }
            } else if (offset < 0) {
                newPassHidden = hiddenPassVal.substring(
                    0,
                    hiddenPassVal.length + offset
                )
                if (cursorLoc !== passwordVal.length) {
                    newPassHidden = hiddenPassVal.substring(0, cursorLoc) + hiddenPassVal.substring(cursorLoc + 1, hiddenPassVal.length);
                }
            } else {
                newPassHidden = hiddenPassVal;
            }
            setPassHidden(newPassHidden);
            props.onInputChange(newPassHidden);

            passHiddenRef.current.value = newPassHidden;
            if (cursorLoc === passwordVal.length) inputRef.current.value = passwordVal
                .substring(0, passwordVal.length - showLength)
                .replace(/./g, "•") +
                passwordVal.substring(
                    passwordVal.length - showLength,
                    passwordVal.length
                );
            clearTimeout(timeoutFunc);
            const timeoutFunction = setTimeout(() => {
                if (inputRef.current) inputRef.current.value = passwordVal.replace(/./g, "•");
            }, 1000);
            setTimeoutFunc(timeoutFunction);
        } else {
            inputRef.current.value = passwordVal;
            passHiddenRef.current.value = passwordVal;
            props.onInputChange(passwordVal);
            setPassHidden(passwordVal);
        }
    }

    useEffect(() => {
        if (inputRef.current && inputRef.current.type !== 'password' && inputRef.current.type !== 'email') {
            if (!!toggle) {
                inputRef.current.value = passHiddenRef.current.value;
            }
            if (!toggle) {
                const len = passHiddenRef.current.value.length;
                let inputMask = "";
                let i = 0;
                while (i < len) {
                    inputMask += "\u2022".toUpperCase();
                    i++;
                }
                inputRef.current.value = inputMask;
            }
        }
    }, [toggle]);

    function toggleFunc() {
        clearTimeout(timeoutFunc);
        setToggle((prevState) => !prevState);
    }

    function pasteFunc(e) {
        if (pasteDisable) {
            e.preventDefault();
        }
    }

    function inpFocusFunc(e) {
        if (props.onFocus) props.onFocus(e);
        setFocussed(true);
    }


    return (

        <div className={inpContClass}>
            <input className="hidden-block" ref={passHiddenRef} disabled />
            <div className={styles['input-icon']}>
                <div className={`${styles['pass-container']} flex`}>
                    <div ref={labelRef} className={styles[labelClass]} > <label htmlFor={props.id}>{props.label}</label> </div>
                    <input id={props.id} disabled={isDisabled} placeholder={props.label} ref={inputRef} className={(props.type === 'password' && brand.name !== AppConstants.BOOST_BRAND) ? styles['prim-input'] + " " + styles['prim-input-spacing'] : styles['prim-input']} name={props.name} type={type} size="35" onBlur={ e => {
                        if (props.onBlur) props.onBlur(e);
                        setFocussed(false);
                    }} onClick={e => props.onClickInp ? props.onClickInp(e) : null} onFocus={inpFocusFunc} onChange={onInpChange} autoComplete={props.autoCompleteName} onPaste={pasteFunc} />
                </div>
                {
                    props.type === 'password' ? <span ref={passIconRef} onClick={toggleFunc}> <PassIcon brandName={brand.name} toggle={toggle} /> </span> : null
                }
            </div>
            <div ref={shadowRef} className={rectShadClass} />
        </div>
    );

}

export default PassComp;