import { Fragment } from 'react';

function InfoIcon(props) {
    return (
        <Fragment>
            <svg id='info-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
                <mask id="mask0_648_636"  maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="inherit" />
                </mask>
                <g mask="url(#mask0_648_636)">
                    <path d="M1.875 20.5L12 3L22.125 20.5H1.875ZM4.45 19H19.55L12 6L4.45 19ZM12 17.8C12.2333 17.8 12.425 17.725 12.575 17.575C12.725 17.425 12.8 17.2333 12.8 17C12.8 16.7667 12.725 16.575 12.575 16.425C12.425 16.275 12.2333 16.2 12 16.2C11.7667 16.2 11.575 16.275 11.425 16.425C11.275 16.575 11.2 16.7667 11.2 17C11.2 17.2333 11.275 17.425 11.425 17.575C11.575 17.725 11.7667 17.8 12 17.8ZM11.25 15.2H12.75V10.2H11.25V15.2Z" fill="inherit" />
                </g>
            </svg>
        </Fragment>
    );
}

export default InfoIcon;