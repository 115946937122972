import boostStyles from './BoostCloseIcon.module.css';
import genesisStyles from './GenesisCloseIcon.module.css';
import infiniteStyles from './InfiniteCloseIcon.module.css';
import { Fragment } from 'react';
import * as AppConstants from '../../../AppConstants';

function CloseIcon(props) {

    let styles;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }

    return (
        <Fragment>
            <svg width="20px" height="20px" fill="text" viewBox="0 0 24 24" role="img" className={styles['closeIcon']}>
                <g>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12,5 L12,19 M5,12 L19,12" transform="rotate(45 12 12)" className={styles['stroke-current']} fill="none"></path>
                </g>
            </svg>
        </Fragment>
    );
}

export default CloseIcon;