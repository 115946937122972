export const INVALID_EMAIL = 'Email Address or Phone Number provided does not match our records. Please try again.';
export const EMAIL_INPUT_LABEL = 'Email or Phone';
export const HOME_PRIM_TEXT = 'Sign In';
export const PASS_PRIM_TEXT = 'Hi ';
export const PASS_SEC_TEXT = 'Verify it’s you to continue';
export const FORGOT_PRIM_TEXT = 'Verify temporary password';
export const MFA_PRIM_TEXT_EMAIL = 'Verify Your Email';
export const MFA_PRIM_TEXT_PHONE = 'Verify Your Phone';
export const MFA_SEC_TEXT_EMAIL = (username => "An email with your one time passcode was just sent to " + username);
export const MFA_SEC_TEXT_PHONE = (username => "A message with your one time passcode was just sent to " + username);
export const EMAIL_VERIFY_PRIM_TEXT = 'Verify your Email';
export const UPDATE_EMAIL_PRIM_TEXT = 'Enter New Email';
export const SET_PASS_PRIM_TEXT = 'Change Password';
export const HOME_SEC_TEXT = "to continue to the Project Genesis app";
export const FORGOT_SEC_TEXT = 'We emailed your temporary password to ';
export const SET_PASS_SEC_TEXT = "Create a new password that is strong and unique";
export const UPDATE_EMAIL_SEC_TEXT = "We'll send you a verification code to confirm the new email.";
export const PASS_INPUT_LABEL = 'Enter Your Password';
export const FORGOT_PASS_INPUT_LABEL = 'Enter Your Password';
export const CREATE_PASS_LABEL = 'New Password';
export const CNFRM_PASS_LABEL = 'Confirm Password';
export const BTN_TEXT = 'Continue';
export const PASS_BTN_TEXT = 'Continue';
export const MFA_BTN_TEXT = 'Continue';
export const CREATE_PASS_BTN_TEXT = 'Continue';
export const DIDNT_CODE = "Didn't receive temporary password?";
export const VERIFY_WITH_OTP = "Didn't receive temporary password?";
export const RESEND_OTP = "Didn't receive the code?";
export const OTP_USE_PASS = "Use password instead";
export const PASS_EMAIL_CODE = "Use email code instead";
export const PASS_SMS_CODE = "Use SMS code instead";
export const PASS_MATCH_ERR = "Passwords don’t match. Try again.";
export const INVALID_PASSWORD = 'Incorrect password. Try again.';
export const INVALID_OTP = 'Incorrect code. Try again.';
export const INVALID_OTP_UPDATE_EMAIL = 'The code is wrong. Check your email again.';
export const EMAIL_EXISTS = 'Sorry, this email address is already in use for a Genesis account.';