import { Fragment } from 'react';
import boostStyles from './BoostCheckMark.module.css';
import genesisStyles from './GenesisCheckMark.module.css';
import infiniteStyles from './InfiniteCheckMark.module.css';
import * as AppConstants from '../../../AppConstants';

function CheckMark(props) {
    let styles;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }

    const checkMarkClass = props.isValid && props.isValid === true ? styles['valid-mark'] : styles['invalid-mark'];
    return (
        <Fragment>
            <svg width="25px" height="24px" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Icons / Checkmark Circle</title>
                <g id="Identity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Create-Password" transform="translate(-531.000000, -528.000000)">
                        <rect className={styles['svg-rect']} x="0" y="0" width="1440" height="1024"></rect>
                        <g id="Group-4" transform="translate(531.000000, 528.000000)">
                            <g id="Shape" transform="translate(3.000000, 2.000000)">
                                <circle className={checkMarkClass} cx="10" cy="10" r="10"></circle>
                                {!!props.isValid ? <path d="M7.99967867,11.8506935 L6.2245547,10.0755696 C6.02978026,9.88079513 5.7141609,9.8802375 5.51869943,10.0743225 L5.12305754,10.4671782 C4.92710665,10.6617491 4.92598799,10.9783296 5.12055895,11.1742805 C5.12097391,11.1746984 5.1213896,11.1751156 5.12180604,11.175532 L7.64612527,13.6998513 C7.84138742,13.8951134 8.15796991,13.8951134 8.35323206,13.6998513 L14.333941,7.71914234 C14.5292031,7.5238802 14.5292031,7.20729771 14.333941,7.01203556 L13.9435839,6.6216785 C13.7481716,6.42675891 13.431779,6.42664871 13.2362309,6.62143212 L7.99967867,11.8506935 L7.99967867,11.8506935 Z" stroke="#FFFFFF" strokeWidth="0.25" fill="#FFFFFF" fillRule="nonzero"></path> : null}
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Fragment>
    );
}

export default CheckMark;