import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//import { getError } from "../../api/PingIndentity";
import { submitEmail } from './../../api/auth';
import * as AppConstants from '../../AppConstants';
import * as BoostPageContents from '../../Boost/PageContents';
import * as GenesisPageContents from '../../Genesis/PageContents';
import * as InfinitePageContents from '../../Infinite/PageContents';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import PassComp from '../PassComp/PassComp';
import HeaderComp from '../HeaderComp/HeaderComp';
import { CSSTransition } from "react-transition-group";
import slideTransition from "../CssTransitions/slide-enter.module.css";
import slideExitTransition from "../CssTransitions/email-first-render.module.css";
import boostStyles from './BoostEmailSignin.module.css';
import genesisStyles from './GenesisEmailSignin.module.css';
import infiniteStyles from './InfiniteEmailSignin.module.css';
import { sendFormErrEvent, sendClickEvent } from '../../Utitlity/AdobeAnalytics'
import { verifyMFAWithPing } from '../../api/PingIdentityMFA';
import { Fragment } from 'react/cjs/react.production.min';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { testEmail } from '../../Utitlity/Misc';

function EmailSignIn() {
    //Email Input
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [otpLogin, setOTPLogin] = useState(false);
    //Goto Next page
    const [show, setShow] = useState(false);
    const [smsOTPCount, setSMSOTPCount] = useState(0);
    const [emailOTPCount, setEmailOTPCount] = useState(0);
    const nodeRef = useRef();
    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const linkRef = useRef();
    const newLinkRef = useRef();
    const captchaRef = useRef(null);
    const history = useHistory();
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));

    useEffect(() => {
        //window.onload = () => {
        //Adobe Analytics Event object for PageViews
        window.adobeDataLayer = window.adobeDataLayer || [];
        const adobeObj = {
            event: 'screen_load',
            web: {
                webPageDetails: {
                    pageViews: {
                        value: 1
                    },
                    name: 'BMWeb|ping-username',
                    domain: brand.name,
                    language: 'en',
                    siteSection: 'username',
                    siteSubSection: 'username',
                    siteSubSubSection: 'username',
                    URL: window.location.href,
                    qsp: window.location.search,
                    platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
                    type: 'login username page'

                }
            }

        }
        window.adobeDataLayer.push(adobeObj);
        // };
    }, [brand.name]);

    let PageContents;
    let styles;
    let hCaptchaKey;
    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            PageContents = BoostPageContents;
            styles = boostStyles;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_BOOST_KEY;
            break;
        case AppConstants.GENESIS_BRAND:
            PageContents = GenesisPageContents;
            styles = genesisStyles;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_GENESIS_KEY;
            break;
        case AppConstants.INFINITE_BRAND:
            PageContents = InfinitePageContents;
            styles = infiniteStyles;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_INFINITE_KEY;
            break;
        default:
            PageContents = BoostPageContents;
            styles = boostStyles;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_BOOST_KEY;
    }

    useEffect(() => {
        setShow(prevState => !prevState);
    }, []);

    useEffect(() => {
        //On Error Scroll to the top of the Screen
        if (error) {
            window.scroll({
                top: -1,
                behavior: 'smooth'
            });

        }
    }, [error]);

    function enableLoadingStyle() {
        buttonRef.current.disabled = true;
        //loaderRef.current.style.display = 'block';
        setLoading(true);
        //setShowBoostAppLink(false);
        if (linkRef.current) linkRef.current.classList.add("disabled");
        if (newLinkRef.current) newLinkRef.current.classList.add("disabled");
        containerRef.current.classList.add("container-main--loading");
    }

    function disableLoadingStyle() {
        buttonRef.current.disabled = false;
        //loaderRef.current.style.display = 'none';
        if (linkRef.current) linkRef.current.classList.remove("disabled");
        if (newLinkRef.current) newLinkRef.current.classList.remove("disabled");
        setLoading(false);
        containerRef.current.classList.remove("container-main--loading");
    }

    function onEmailChange(e) {
        setError(false);
        setEmail(e.target.value.trim());
    }

    function emailInpClick(e) {
        var isChromium = window.chrome;
        var winNav = window.navigator;
        var vendorName = winNav.vendor;
        var isIOSChrome = winNav.userAgent.match("CriOS");
        //Code to make Autofill work
        if (!isIOSChrome && !(isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc.")) {
            const hiddenPass = document.getElementById("password");
            hiddenPass.focus();
            e.target.focus();
        }
    }

    function onCaptchaSubmit(token) {
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        submitEmail(email, false, token, function (details) {
            if (details === 'server-error') {
                disableLoadingStyle();
                sendFormErrEvent('Ping: UsernameSearch', 'PingUsernamePage' + (isMobile ? '_Mob: ' : ': ') + 'Username Search API Fail');
                setError(AppConstants.SERVER_ISSUE);
            } else {
                const data = details.data;
                if (data && data.id) {
                    const passChangeReq = data.passwordChangeRequired;
                    sessionStorage.setItem('accountNumber', data.id);
                    sessionStorage.setItem(AppConstants.FIRSTNAME, data.firstName);
                    sessionStorage.setItem(AppConstants.USER_EMAIL, data.emailAddress);
                    sessionStorage.setItem('passChangeReq', passChangeReq);
                    const username = sessionStorage.getItem(AppConstants.USERNAME) || email;
                    if (!!passChangeReq) {
                        verifyMFAWithPing(username).then(res => {
                            disableLoadingStyle();
                            setShow(false);
                        }).catch(err => {
                            disableLoadingStyle();
                            sendFormErrEvent('Ping: UsernameSearch', 'PingUsernamePage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to send one time code');
                            if (testEmail(email)) {
                                setEmailOTPCount(emailOTPCount + 1);
                                if (emailOTPCount + 1 > 1) return setError(AppConstants.EMAIL_OTP_ISSUE);
                            } else {
                                setSMSOTPCount(smsOTPCount + 1);
                                if (smsOTPCount + 1 > 1) return setError(AppConstants.SMS_OTP_ISSUE);
                            }
                            setError(AppConstants.SERVER_ISSUE);
                        });
                    } else {
                        if (!testEmail(email) && brand.name !== AppConstants.GENESIS_BRAND) {
                            verifyMFAWithPing(username).then(res => {
                                disableLoadingStyle();
                                setOTPLogin(true);
                                setShow(false);
                            }).catch(err => {
                                disableLoadingStyle();
                                sendFormErrEvent('Ping: UsernameSearch', 'PingUsernamePage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to send one time code');
                                const smsOTPError = <span>It seems like you're having trouble getting the verification code. Try <a href='/otp/signin' style={{ color: '#fff', textDecoration: 'underline' }} onClick={(e) => {
                                    e.preventDefault();
                                    setShow(false);
                                }}>using your password</a></span>
                                setError(smsOTPError);
                            });
                        } else {
                            disableLoadingStyle();
                            setShow(false);
                        }
                    }
                } else {
                    disableLoadingStyle();
                    sendFormErrEvent('Ping: UsernameSearch', 'PingUsernamePage' + (isMobile ? '_Mob: ' : ': ') + 'Empty Response from Username Search API');
                    setError(PageContents.INVALID_EMAIL);
                }
            }
        });

    }

    const onCaptchErr = error => {
        console.log("HCaptcha [onError]:", error);
    };

    function onSubmitEmail(e) {
        e.preventDefault();
        setError(false);
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        if (!email || (!testEmail(email) && !(/^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/).test(email))) {
            sendFormErrEvent('Ping: UsernameSearch', 'PingUsernamePage' + (isMobile ? '_Mob: ' : ': ') + 'Username Regex Validation Fail');
            return setError(AppConstants.REGEX_EMAIL_FAIL);
        }
        enableLoadingStyle();
        captchaRef.current.execute();
    }

    return (
        <div className="container-main flex flex-center-all">
            <HeaderComp isBackBtnReq="false" loading={loading} />
            <CSSTransition nodeRef={nodeRef} in={show} timeout={200} classNames={history.action === 'POP' ? slideExitTransition : slideTransition} onExited={() => {
                const passChangeReq = sessionStorage.getItem('passChangeReq');
                if (otpLogin) {
                    history.push('/otp/signin');
                } else if (passChangeReq && passChangeReq.includes('true')) history.push('/mfa');
                else history.push('/password');
            }} mountOnEnter unmountOnExit>
                <div className="identity-body flex flex-center-all" ref={nodeRef} >
                    <div className="container-load flex">
                        <form noValidate action="/" autoComplete="on" ref={containerRef} onSubmit={onSubmitEmail} className="email-box flex" >
                            {
                                brand.name !== AppConstants.GENESIS_BRAND && !!error ?
                                    <ErrorBlock message={error} />
                                    :
                                    null
                            }

                            <HCaptcha
                                sitekey={hCaptchaKey}
                                onVerify={onCaptchaSubmit}
                                ref={captchaRef}
                                onError={onCaptchErr}
                                size="invisible"
                            />
                            <h1 id="h111" className="primary-text" >
                                {PageContents.HOME_PRIM_TEXT}
                            </h1>
                            <div className='prof-container transparent'>
                                <p className="secondary-text">{PageContents.HOME_SEC_TEXT}</p>
                            </div>
                            
                            <PassComp id="user-text-field" error={error} autoCompleteName="username" isDisabled={loading} isFocussed="true" label={PageContents.EMAIL_INPUT_LABEL} type="email" name="email" onClickInp={emailInpClick} onInputChange={onEmailChange} />

                            {
                                brand.name === AppConstants.GENESIS_BRAND && !!error ?
                                    <p className="err-block">{error}</p>
                                    :
                                    null
                            }

                            <input type="password" id="password" className="hidden-block" name="hiddenPassword" readOnly />

                            <button
                                ref={buttonRef}
                                type="submit"
                                id="prim-button"
                                className="modal--button--primary primary"
                                onClick={() => {
                                    if (brand.name === AppConstants.BOOST_BRAND) {
                                        sendClickEvent(
                                            'Continue Button',
                                            PageContents.BTN_TEXT,
                                            "https://id.boostmobile.com/",
                                            'BMWeb|ping-username'
                                        );
                                    }
                                }}
                            >
                                {PageContents.BTN_TEXT}
                            </button>


                            {
                                brand.name === AppConstants.BOOST_BRAND && !sessionStorage.getItem(AppConstants.IS_MOBILE) ?
                                    <Fragment>
                                        <a href={brand.magentoHost + "/shop/nr/check/iccid"} ref={newLinkRef} className={styles['missing-footer']} style={{ marginTop: 32 }} onClick={e => {
                                            const hrefLink = e.currentTarget.href;
                                            e.preventDefault();
                                            sendClickEvent("SignIn Footer", "Ping NR Activation", hrefLink, "BMWeb|ping-username");
                                            setTimeout(() => window.location.href = hrefLink, "2000");
                                        }}>
                                            New Customer needing to activate?
                                        </a>
                                    </Fragment> : null
                            }

                        </form>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default EmailSignIn;