import { useEffect, useState, useRef, Fragment } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom';
import * as AppConstants from '../../AppConstants';
import * as BoostPageContents from '../../Boost/PageContents';
import * as GenesisPageContents from '../../Genesis/PageContents';
import * as InfinitePageContents from '../../Infinite/PageContents';
import { getTillAuthCode } from '../../api/PingIndentityPKCE';
import HeaderComp from '../HeaderComp/HeaderComp';
import { patchPasswordFlag } from '../../api/auth';
import { verifyMFAWithPing } from '../../api/PingIdentityMFA';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import PassComp from '../PassComp/PassComp';
import ModalComp from "../ModalComp/ModalComp";
import ProfileIcon from "../Icons/ProfileIcon/ProfileIcon";
import { CSSTransition } from "react-transition-group";
import slideTransition from "../CssTransitions/slide.module.css";
import slideExitTransition from "../CssTransitions/slide-exit.module.css";
import { sendFormErrEvent } from '../../Utitlity/AdobeAnalytics';

function ForgotPassword() {
    const [show, setShow] = useState(false);
    const email = sessionStorage.getItem(AppConstants.USER_EMAIL);
    const userName = sessionStorage.getItem(AppConstants.USERNAME);
    const userNameFormatted = ((/^[0-9]{10}$/).test(userName)) ? getNumberFormat(userName) : userName.toLowerCase();
    const accNum = sessionStorage.getItem('accountNumber');
    const [pass, setPass] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    //Flag for Confirmation Modal to Send onetime code
    const [loadModal, setLoadModal] = useState(false);
    const [transitionTo, setTransitionTo] = useState('Continue');
    const history = useHistory();
    const redirectURI = sessionStorage.getItem(AppConstants.REDIRECT_URI) ? "?redirect_uri=" + sessionStorage.getItem(AppConstants.REDIRECT_URI) : "";
    const nodeRef = useRef();
    const buttonRef = useRef(null);
    //const linkRef = useRef();
    const otpLinkRef = useRef();
    const containerRef = useRef(null);

    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    let PageContents;
    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            PageContents = BoostPageContents;
            break;
        case AppConstants.GENESIS_BRAND:
            PageContents = GenesisPageContents;
            break;
        case AppConstants.INFINITE_BRAND:
            PageContents = InfinitePageContents;
            break;
        default:
            PageContents = BoostPageContents;
    }

    useEffect(() => {
        if (error) {
            window.scroll({
                top: -1,
                behavior: 'smooth'
            });

        }
    }, [error]);

    useEffect(() => {
        window.adobeDataLayer = window.adobeDataLayer || [];
        const adobeObj = {
            event: 'screen_load',
            web: {
                webPageDetails: {
                    pageViews: {
                        value: 1
                    },
                    name: 'BMWeb|ping-temp-password',
                    domain: brand.name,
                    language: 'en',
                    siteSection: 'temp-password',
                    siteSubSection: 'temp-password',
                    siteSubSubSection: 'temp-password',
                    URL: window.location.href,
                    qsp: window.location.search,
                    platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
                    type: 'login temporary password page'

                }
            }

        }
        window.adobeDataLayer.push(adobeObj);
    }, [brand.name]);

    useEffect(() => {
        if (loadModal && buttonRef && otpLinkRef && buttonRef.current && otpLinkRef.current) {
            buttonRef.current.disabled = true;
            //linkRef.current.classList.add("disabled");
            otpLinkRef.current.classList.add("disabled");
            containerRef.current.classList.add("container-main--loading");
        } else if (!loadModal && !loading && buttonRef && otpLinkRef && buttonRef.current && otpLinkRef.current) {
            buttonRef.current.disabled = false;
            //linkRef.current.classList.remove("disabled");
            otpLinkRef.current.classList.remove("disabled");
            containerRef.current.classList.remove("container-main--loading");
        }
    }, [loadModal, loading]);

    function onPassChange(e) {
        setError(false);
        setPass(e);
    }

    function enableLoadingStyle() {
        buttonRef.current.disabled = true;
        //linkRef.current.classList.add("disabled");
        otpLinkRef.current.classList.add("disabled");
        setLoading(true);
        containerRef.current.classList.add("container-main--loading");
    }

    function disableLoadingStyle() {
        buttonRef.current.disabled = false;
        //linkRef.current.classList.remove("disabled");
        otpLinkRef.current.classList.remove("disabled");
        setLoading(false);
        containerRef.current.classList.remove("container-main--loading");
    }

    useEffect(() => {
        setShow(prevState => !prevState);
    }, []);

    function onBackClick(e) {
        e.preventDefault();
        setTransitionTo('Back');
        setLoadModal(false);
        setShow(false);
    }

    function sendTempCodeWithApigee(e) {
        e.preventDefault();
        setError(false);
        setLoadModal(true);
        /*enableLoadingStyle();
        return sendTempCode(accNum, email).then((res) => {
            disableLoadingStyle();
        }).catch(err => {
            disableLoadingStyle();
            if (err.error && err.error.code) return setError(err.error.code);
            setError(AppConstants.SERVER_ISSUE);
        });*/
    }

    function getNumberFormat(userName) {
        return userName.substr(0, 3) + "-" + userName.substr(3, 3) + "-" + userName.substr(6, 4);
    }

    function sendMFACode(e) {
        e.preventDefault();
        setError(false);
        enableLoadingStyle();
        setLoadModal(false);
        return verifyMFAWithPing(userName).then(res => {
            disableLoadingStyle();
            setTransitionTo("mfa");
            setShow(false);
        }).catch(err => {
            disableLoadingStyle();
            const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
            sendFormErrEvent('Ping: TempPasswordForm', 'PingTempPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to send one time code');
            setError(AppConstants.SERVER_ISSUE);
        });
    }

    function authTempUser(e) {
        e.preventDefault();
        setError(false);
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        if (pass === '') {
            sendFormErrEvent('Ping: TempPasswordForm', 'PingTempPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Password validation failed');
            return setError(AppConstants.REGEX_PASS_FAIL)
        }
        enableLoadingStyle();
        getTillAuthCode(userName, pass).then(flowId => {
            return patchPasswordFlag(accNum);
        }).then(res => {
            disableLoadingStyle();
            setShow(false);
        }).catch(err => {
            disableLoadingStyle();
            if (err.error && err.error.code && err.error.code === 'INVALID_CREDENTIALS') {
                sendFormErrEvent('Ping: TempPasswordForm', 'PingTempPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Entered incorrect password');
                setError(PageContents.INVALID_PASSWORD);
            } else if (err.error && err.error.code && err.error.code === AppConstants.ACCOUNT_LOCKED) {
                sendFormErrEvent('Ping: TempPasswordForm', 'PingTempPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Account locked');
                setError(AppConstants.ACCOUNT_LOCKED_MSG);
            } else {
                sendFormErrEvent('Ping: TempPasswordForm', 'PingTempPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Server issue while verifying the password');
                setError(AppConstants.SERVER_ISSUE);
            }
        });
    }

    if (!userName) {
        return (<Redirect to="/" />);
    }

    return (
        <div className="container-main flex flex-center-all">
            <ModalComp primText={AppConstants.FORGOT_PASS_MODAL_PRIM_TEXT} showComp={loadModal} closeModal={e => setLoadModal(false)}
                secText={<Fragment>A temporary code will be sent to <strong> {userNameFormatted} </strong> to create your password </Fragment>}
                btnText={AppConstants.FRGT_PASS_MODAL_BTN_TXT}
                sendCode={sendMFACode}
                brandName={brand.name} />
            <HeaderComp isBackBtnReq={brand.name === AppConstants.BOOST_BRAND ? "true" : "false"} redirectURI={"/" + redirectURI} loading={loading} onClickCallback={onBackClick} />
            <CSSTransition nodeRef={nodeRef} in={show} timeout={200} classNames={transitionTo === 'Back' ? slideTransition : slideExitTransition} onExited={() => {
                if (transitionTo === 'Back') history.push("/" + redirectURI);
                else if (transitionTo === 'mfa') history.push('/mfa');
                else history.push('/setPassword');
            }} mountOnEnter unmountOnExit>
                <div className="identity-body flex flex-center-all" ref={nodeRef} >
                    <div className="container-load flex">
                        <form ref={containerRef} action="/" autoComplete="on" onSubmit={authTempUser} className="email-box flex">
                            {
                                brand.name === AppConstants.BOOST_BRAND && !!error ?
                                    <ErrorBlock message={error} />
                                    :
                                    null
                            }
                            <h1 className="primary-text">{PageContents.FORGOT_PRIM_TEXT}</h1>

                            {
                                brand.name === AppConstants.BOOST_BRAND ?
                                    (<Fragment>
                                        <div className='prof-container'>
                                            <ProfileIcon brandName={brand.name} />
                                            <p className="secondary-text sec-text-inside">{userNameFormatted}</p>
                                        </div>
                                        <p className="secondary-text">{PageContents.FORGOT_SEC_TEXT + (email ? email.toLowerCase() : "")}</p>
                                    </Fragment>
                                    ) :

                                    (<Fragment>
                                        <p className="secondary-text-ext">{userNameFormatted}</p>
                                        <div className='prof-container transparent'>
                                            <p className="secondary-text">{PageContents.FORGOT_SEC_TEXT + (email ? email.toLowerCase() : "")}</p>
                                        </div>
                                    </Fragment>
                                    )
                            }


                            <input type="email" className="hidden-block" name="username" value={userName} autoComplete="username" readOnly />

                            <PassComp id="current-pass-text-field" autoCompleteName="current-password" isDisabled={loading} error={error} isFocussed="true" label={PageContents.FORGOT_PASS_INPUT_LABEL} type="password" name="password" onInputChange={onPassChange} />

                            {
                                brand.name !== AppConstants.BOOST_BRAND && !!error ?
                                    <p className="err-block">{error}</p>
                                    :
                                    null
                            }

                            <button type="submit" ref={buttonRef} className="modal--button--primary primary" > {PageContents.BTN_TEXT} </button>
                        </form>
                    </div>
                    {/* <Link to="#" ref={linkRef} className="missing-footer" onClick={sendTempCodeWithApigee} >{PageContents.DIDNT_CODE}</Link> */}
                    <Link to="#" ref={otpLinkRef} className="missing-footer" onClick={sendTempCodeWithApigee} >{PageContents.VERIFY_WITH_OTP}</Link>
                </div>
            </CSSTransition >            
        </div >
    );
}

export default ForgotPassword;