import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//import { getError } from "../../api/PingIndentity";
import { submitEmail } from '../../api/auth';
import { verifyMFAWithPing, sendMFAWithP1AS } from '../../api/PingIdentityMFA';
import * as AppConstants from '../../AppConstants';
import * as BoostPageContents from '../../Boost/PageContents';
import * as GenesisPageContents from '../../Genesis/PageContents';
import * as InfinitePageContents from '../../Infinite/PageContents';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import PassComp from '../PassComp/PassComp';
import HeaderComp from '../HeaderComp/HeaderComp';
import { CSSTransition } from "react-transition-group";
import slideTransition from "../CssTransitions/slide-enter.module.css";
import slideExitTransition from "../CssTransitions/email-first-render.module.css";
import { sendFormErrEvent, sendClickEvent } from '../../Utitlity/AdobeAnalytics';
import { testEmailComplex } from '../../Utitlity/Misc';
import ModalComp from "../ModalComp/ModalComp";
import HCaptcha from '@hcaptcha/react-hcaptcha';

function EmailUpdate() {
    //Email Input
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadModal, setLoadModal] = useState(false);
    const [show, setShow] = useState(false);

    const nodeRef = useRef();
    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const captchaRef = useRef(null);
    const history = useHistory();
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));

    function sendMFACode(e) {
        e.preventDefault();
        sendClickEvent("email update modal", "email-update-modal-continue", window.location.href, "BMWeb|email update modal");
        setError(false);
        enableLoadingStyle();
        setLoadModal(false);
        sessionStorage.setItem(AppConstants.USER_EMAIL, email);
        if(process.env.REACT_APP_ENABLE_OTP_P1AS){
            return sendMFAWithP1AS(email).then(res => {
                sessionStorage.setItem(AppConstants.DEVICE_AUTHENTICATION_ID, res?.data?.id);
                disableLoadingStyle();
                setShow(false);
            }).catch(err => {
                disableLoadingStyle();
                const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                sendFormErrEvent('Ping: UpdateEmail', 'PingUpdateEmailPage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to send one time code');
                setError(AppConstants.SERVER_ISSUE);
            });
        } else {
            return verifyMFAWithPing(email).then(res => {
                disableLoadingStyle();
                setShow(false);
            }).catch(err => {
                disableLoadingStyle();
                const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                sendFormErrEvent('Ping: UpdateEmail', 'PingUpdateEmailPage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to send one time code');
                setError(AppConstants.SERVER_ISSUE);
            });
        }
        
    }

    useEffect(() => {
        //Adobe Analytics Event object for PageViews
        window.adobeDataLayer = window.adobeDataLayer || [];
        const adobeObj = {
            event: 'screen_load',
            web: {
                webPageDetails: {
                    pageViews: {
                        value: 1
                    },
                    name: 'BMWeb|Update Email Initiate',
                    domain: brand.name,
                    language: 'en',
                    siteSection: 'update-email',
                    siteSubSection: 'update-email',
                    siteSubSubSection: 'update-email',
                    URL: window.location.href,
                    qsp: window.location.search,
                    platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
                    type: 'login update email page'

                }
            }

        }
        window.adobeDataLayer.push(adobeObj);
        window.adobeDataLayer.push({
            event: "user_account_update",
            web: {
                user: {
                    accountUpdate: {
                        updateStart: {
                            value: 1
                        }
                    },
                    updateName: "email update"
                }
            }
        });
    }, [brand.name]);

    let PageContents;
    let hCaptchaKey;
    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            PageContents = BoostPageContents;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_BOOST_KEY;
            break;
        case AppConstants.GENESIS_BRAND:
            PageContents = GenesisPageContents;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_GENESIS_KEY;
            break;
        case AppConstants.INFINITE_BRAND:
            PageContents = InfinitePageContents;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_INFINITE_KEY;
            break;
        default:
            PageContents = BoostPageContents;
            hCaptchaKey = process.env.REACT_APP_HCAPTCHA_BOOST_KEY;
    }

    useEffect(() => {
        setShow(prevState => !prevState);
    }, []);

    useEffect(() => {
        //On Error Scroll to the top of the Screen
        if (error) {
            window.scroll({
                top: -1,
                behavior: 'smooth'
            });

        }
    }, [error]);

    function enableLoadingStyle() {
        buttonRef.current.disabled = true;
        //loaderRef.current.style.display = 'block';
        setLoading(true);
        containerRef.current.classList.add("container-main--loading");
    }

    function disableLoadingStyle() {
        buttonRef.current.disabled = false;
        //loaderRef.current.style.display = 'none';    
        setLoading(false);
        containerRef.current.classList.remove("container-main--loading");
    }

    function onEmailChange(e) {
        setError(false);
        setEmail(e.target.value.trim());
    }

    function emailInpClick(e) {
        var isChromium = window.chrome;
        var winNav = window.navigator;
        var vendorName = winNav.vendor;
        var isIOSChrome = winNav.userAgent.match("CriOS");
        //Code to make Autofill work
        if (!isIOSChrome && !(isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc.")) {
            const hiddenPass = document.getElementById("password");
            hiddenPass.focus();
            e.target.focus();
        }
    }

    function onCaptchaSubmit(token) {
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        submitEmail(email, true, token, function (details) {
            if (details === 'server-error') {
                disableLoadingStyle();
                sendFormErrEvent('Ping: UpdateEmail', 'PingUpdateEmailPage' + (isMobile ? '_Mob: ' : ': ') + 'Username Search API Fail');
                setError(AppConstants.SERVER_ISSUE);
            } else {
                const data = details.data;
                if (data && data.id) {
                    sendFormErrEvent('Ping: UpdateEmail', 'PingUpdateEmailPage' + (isMobile ? '_Mob: ' : ': ') + 'Email Already exists');
                    setError(PageContents.EMAIL_EXISTS);
                    disableLoadingStyle();
                } else {
                    disableLoadingStyle();
                    setLoadModal(true);
                }
            }
        });

    }

    const onCaptchErr = error => {
        console.log("HCaptcha [onError]:", error);
    };

    function onSubmitEmail(e) {
        e.preventDefault();
        setError(false);
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        if (!email || !testEmailComplex(email)) {
            sendFormErrEvent('Ping: UpdateEmail', 'PingUpdateEmailPage' + (isMobile ? '_Mob: ' : ': ') + 'Email Regex Validation Fail');
            return setError("Please enter a valid email address");
        }
        sendClickEvent("email update footer", "email-update-continue", window.location.href, "BMWeb|Update Email Initiate");
        enableLoadingStyle();
        captchaRef.current.execute();
    }

    return (
        <div className="container-main flex flex-center-all">

            <ModalComp primText="Confirm Email Update" showComp={loadModal}
                closeModal={e => {
                    sendClickEvent("email update modal", "email-update-modal-cancel", window.location.href, "BMWeb|email update modal");
                    setLoadModal(false);
                }}
                secText="You will be required to verify a code sent to the new email address, and to log in again"
                btnText="Continue"
                sendCode={sendMFACode}
                brandName={brand.name}
                noIcon={true}
                showCancel={true} />

            {brand.name !== AppConstants.INFINITE_BRAND ? <HeaderComp isBackBtnReq="false" loading={loading} /> : null}
            <CSSTransition nodeRef={nodeRef} in={show} timeout={200} classNames={history.action === 'POP' ? slideExitTransition : slideTransition} onExited={() => history.push('/mfa')} mountOnEnter unmountOnExit>
                <div className="identity-body flex flex-center-all" ref={nodeRef} >
                    <div className="container-load flex">
                        <form noValidate action="/" autoComplete="on" ref={containerRef} onSubmit={onSubmitEmail} className="email-box flex">
                            {
                                brand.name !== AppConstants.GENESIS_BRAND && !!error ?
                                    <ErrorBlock message={error} />
                                    :
                                    null
                            }

                            <HCaptcha
                                sitekey={hCaptchaKey}
                                onVerify={onCaptchaSubmit}
                                ref={captchaRef}
                                onError={onCaptchErr}
                                size="invisible"
                            />
                            <h1 id="h111" className="primary-text" >
                                {PageContents.UPDATE_EMAIL_PRIM_TEXT}
                            </h1>
                            <div className='prof-container transparent'>
                                <p className="secondary-text">{PageContents.UPDATE_EMAIL_SEC_TEXT}</p>
                            </div>
                            <PassComp id="user-text-field" error={error} autoCompleteName="username" isDisabled={loading} isFocussed="true" label="New Email" type="email" name="email" onClickInp={emailInpClick} onInputChange={onEmailChange} />

                            {
                                brand.name === AppConstants.GENESIS_BRAND && !!error ?
                                    <p className="err-block">{error}</p>
                                    :
                                    null
                            }

                            <input type="password" id="password" className="hidden-block" name="hiddenPassword" readOnly />

                            <button ref={buttonRef} type="submit" id="prim-button" className="modal--button--primary primary" >{PageContents.BTN_TEXT}</button>

                        </form>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default EmailUpdate;