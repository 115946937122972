import React, { Fragment, useEffect, lazy, Suspense, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import brandData from './Utitlity/BrandData';
import * as AppConstants from './AppConstants';
import { HelmetProvider } from 'react-helmet-async';

const ThemeSelector = ({ children }) => {
  const BoostTheme = lazy(() => import('./Boost/BoostTheme'));
  const GenesisTheme = lazy(() => import('./Genesis/GenesisTheme'));
  const InfiniteTheme = lazy(() => import('./Infinite/InfiniteTheme'));
  const [theme, setTheme] = useState('');
  const [renderChild, setRenderChild] = useState(false);
  useEffect(() => {
    sessionStorage.clear();
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    const queryParams = new URLSearchParams(window.location.search);
    //const brandParam = queryParams.get('brand');
    //Find the brand and load corresponding UI Screens
    const hostname = window.location.hostname;
    let brand = brandData.find(brand => hostname.includes(brand.name.toLowerCase()));
    //let brand = null;
    //if (brandParam) brand = brandData.find(brand => brand.name === brandParam);
    if (!brand) brand = brandData[1];
    if (document.cookie.split(';').some((item) => item.trim().startsWith('TS01f42ef0='))) {
      document.cookie = "TS01f42ef0=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    sessionStorage.setItem(AppConstants.BRAND, JSON.stringify(brand));
    const redirectParam = queryParams.get('redirect_uri');
    const actionParam = queryParams.get('action');
    if (actionParam && actionParam === AppConstants.UPDATE_EMAIL) sessionStorage.setItem(AppConstants.ACTION, AppConstants.UPDATE_EMAIL);
    const magentohost = brand.magentoHost;
    //Is Mobile or Web            
    if (redirectParam && !(redirectParam.includes("http://") || redirectParam.includes("https://"))) {
      sessionStorage.setItem(AppConstants.IS_MOBILE, true);
      sessionStorage.setItem(AppConstants.REDIRECT_URI, redirectParam);
    } else if (redirectParam && redirectParam.includes("https://")) {
      //For Web if its not already logged out in commerce then send a request to logout in commerce
      if (!(actionParam && (actionParam === 'logout' || actionParam === AppConstants.UPDATE_EMAIL))) {
        const domain = (new URL(redirectParam));
        return window.location.href = domain.origin + '/shop/customer/account/logout';
      }
      sessionStorage.setItem(AppConstants.REDIRECT_URI, redirectParam);
    } else {
      //For Web if its not already logged out in commerce then send a request to logout in commerce
      if (!(actionParam && (actionParam === 'logout' || actionParam === AppConstants.UPDATE_EMAIL))) {
        return window.location.href = magentohost + '/shop/customer/account/logout';
      }
    }

    setTheme(brand.name);
    setRenderChild(true);
  }, []);
  return (
    <Fragment>
      <Suspense fallback={<>Loading...</>}>
        {(theme === AppConstants.BOOST_BRAND) && <BoostTheme />}
        {(theme === AppConstants.GENESIS_BRAND) && <GenesisTheme />}
        {(theme === AppConstants.INFINITE_BRAND) && <InfiniteTheme />}
      </Suspense>
      {renderChild ? children : null}
    </Fragment>
  );
}

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <BrowserRouter>
        <ThemeSelector>
          <App />
        </ThemeSelector>
      </BrowserRouter>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
