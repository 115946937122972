import boostStyles from './BoostProfileIcon.module.css';
import genesisStyles from './GenesisProfileIcon.module.css';
import infiniteStyles from './InfiniteProfileIcon.module.css';
import { Fragment } from 'react';
import * as AppConstants from '../../../AppConstants';

function ProfileIcon(props) {

    let styles;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;   
        default:
            styles = boostStyles;
    }

    return (
        <Fragment>
            <svg className={styles['prof-icon-main']} width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Profile Icon</title>
                <g id="Identity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <g id="Email-Password-Window" className={styles['profile-icon']} transform="translate(-623.000000, -199.000000)" strokeWidth="1.5">
                        <g id="Group" transform="translate(623.000000, 198.000000)">
                            <g id="Group-2" transform="translate(0.000000, 1.000000)">
                                <g id="Regular" transform="translate(2.000000, 2.000000)">
                                    <circle id="Oval" cx="10" cy="8.125" r="4.375"></circle>
                                    <path d="M15.7608333,17.3966667 C14.2362899,15.8625492 12.1628105,14.9998815 10,14.9998815 C7.83718949,14.9998815 5.76371015,15.8625492 4.23916667,17.3966667" id="Shape"></path>
                                    <circle id="Oval" cx="10" cy="10" r="9.375"></circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Fragment>
    );
}

export default ProfileIcon;