export const INVALID_EMAIL = 'Email Address or Phone Number provided does not match our records. Please try again.';
export const EMAIL_INPUT_LABEL = 'Phone Number or Email';
export const HOME_PRIM_TEXT = 'Welcome to Boost Mobile';
export const PASS_PRIM_TEXT = 'Enter your password';
export const FORGOT_PRIM_TEXT = 'Verify temporary password';
export const MFA_PRIM_TEXT_EMAIL = 'Verify your code';
export const MFA_PRIM_TEXT_PHONE = 'Verify your code';
export const MFA_SEC_TEXT_EMAIL = (username => <span> An email with your one time passcode was just sent to <b>{username}</b></span>);
export const MFA_SEC_TEXT_PHONE = (username => <span> A message with your one time passcode was just sent to <b>{username}</b></span>);
export const EMAIL_VERIFY_PRIM_TEXT = 'Verify your Email';
export const UPDATE_EMAIL_PRIM_TEXT = 'Update Email';
export const SET_PASS_PRIM_TEXT = 'Enter your new password';
export const HOME_SEC_TEXT = "Enter your active Boost Mobile phone number or account email address to get started";
export const FORGOT_SEC_TEXT = 'We emailed your temporary password to ';
export const SET_PASS_SEC_TEXT = 'Create a password to secure your account';
export const UPDATE_EMAIL_SEC_TEXT = 'This address is used for login and account communication';
export const PASS_INPUT_LABEL = 'Password';
export const FORGOT_PASS_INPUT_LABEL = 'Password';
export const CREATE_PASS_LABEL = 'Create Password';
export const CNFRM_PASS_LABEL = 'Confirm Password';
export const BTN_TEXT = 'Continue';
export const PASS_BTN_TEXT = 'Continue';
export const MFA_BTN_TEXT = 'Continue';
export const CREATE_PASS_BTN_TEXT = 'Continue';
export const DIDNT_CODE = "Didn't receive temporary password?";
export const VERIFY_WITH_OTP = "Didn't receive temporary password?";
export const RESEND_OTP = "Resend Code";
export const OTP_USE_PASS = "Use password instead";
export const PASS_EMAIL_CODE = "Use email code instead";
export const PASS_SMS_CODE = "Use SMS code instead";
export const PASS_MATCH_ERR = "Passwords don’t match, please try again";
export const INVALID_PASSWORD = 'It looks like you entered an incorrect password. Please try again.';
export const INVALID_OTP = 'Please enter a valid code or resend the code if not received';
export const INVALID_OTP_UPDATE_EMAIL = 'The code is wrong. Check your email again.';
export const EMAIL_EXISTS = 'Sorry, this email address is already in use for a Boost Mobile account.';