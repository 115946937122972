import { useState, useEffect, useRef } from 'react';
import HeaderComp from '../HeaderComp/HeaderComp';
import { Redirect, useHistory } from 'react-router-dom';
import { patchNewPassword } from './../../api/auth';
import { getTillAuthCode } from '../../api/PingIndentity';
import { authUserWithPing, getError } from './../../api/PingIndentityPKCE';
import boostStyles from './BoostSetPassword.module.css';
import genesisStyles from './GenesisSetPassword.module.css';
import infiniteStyles from './InfiniteSetPassword.module.css';
import * as AppConstants from '../../AppConstants';
import * as BoostPageContents from '../../Boost/PageContents';
import * as GenesisPageContents from '../../Genesis/PageContents';
import * as InfinitePageContents from '../../Infinite/PageContents';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import PassComp from '../PassComp/PassComp';
import { CSSTransition } from "react-transition-group";
import slideExitTransition from "../CssTransitions/slide-exit-delay.module.css";
import slideTransition from "../CssTransitions/slide.module.css";
import CheckMark from '../Icons/CheckMark/CheckMark';
import { sendFormErrEvent } from '../../Utitlity/AdobeAnalytics';

function SetPassword() {
    const [show, setShow] = useState(false);
    const nodeRef = useRef();
    //const email = sessionStorage.getItem(AppConstants.USER_EMAIL);
    const userName = sessionStorage.getItem(AppConstants.USERNAME);;
    const access_token = sessionStorage.getItem('access_token');
    const [transitionTo, setTransitionTo] = useState('Continue');
    const [passwordOne, setPasswordOne] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("");
    const [btnDisable, setBtnDisable] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [containsUL, setContainsUL] = useState(false); // uppercase letter
    const [containsLL, setContainsLL] = useState(false); // lowercase letter
    const [containsN, setContainsN] = useState(false); // number
    const [containsSC, setContainsSC] = useState(false); // special character
    const [contains8C, setContains8C] = useState(false); // min 8 characters
    const [passwordMatch, setPasswordMatch] = useState(false); // passwords match   

    const containerRef = useRef(null);

    const checkRef = useRef(null);

    const redirectURI = sessionStorage.getItem(AppConstants.REDIRECT_URI) ? "?redirect_uri=" + sessionStorage.getItem(AppConstants.REDIRECT_URI) : "";
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const magentoHostURL = brand.magentoHost;
    const pingHostURL = process.env.REACT_APP_PING_HOST;
    let styles;
    let PageContents;

    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            PageContents = BoostPageContents;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            PageContents = GenesisPageContents;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            PageContents = InfinitePageContents;
            break;
        default:
            styles = boostStyles;
            PageContents = BoostPageContents;
    }

    useEffect(() => {
        window.adobeDataLayer = window.adobeDataLayer || [];
        const adobeObj = {
            event: 'screen_load',
            web: {
                webPageDetails: {
                    pageViews: {
                        value: 1
                    },
                    name: 'BMWeb|ping-set-password',
                    domain: brand.name,
                    language: 'en',
                    siteSection: 'set-password',
                    siteSubSection: 'set-password',
                    siteSubSubSection: 'set-password',
                    URL: window.location.href,
                    qsp: window.location.search,
                    platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
                    type: 'login set password page'

                }
            }

        }
        window.adobeDataLayer.push(adobeObj);
    }, [brand.name]);

    // labels and state boolean corresponding to each validation
    const mustContainData = [
        ["Upper & lowercase letters", (containsUL && containsLL)],
        ["At least one number", containsN],
        ["At least one special character", containsSC],
        ["8 or more characters", contains8C]
    ];

    useEffect(() => {
        if (error) {
            window.scroll({
                top: -1,
                behavior: 'smooth'
            });

        }
    }, [error]);

    function onPassChange(e) {
        setError(false);
        setPasswordOne(e);
    }

    function onPassConfirmChange(e) {
        setError(false);
        setPasswordTwo(e);
    }

    function enableLoadingStyle() {
        setBtnDisable(true);
        setLoading(true);
        containerRef.current.classList.add("container-main--loading");
    }

    function disableLoadingStyle() {
        setBtnDisable(false);
        setLoading(false);
        containerRef.current.classList.remove("container-main--loading");
    }

    function onBackClick(e) {
        e.preventDefault();
        setTransitionTo('Back');
        setShow(false);
    }

    function submitToMag() {
        const access_token = sessionStorage.getItem('access_token');
        const refresh_token = sessionStorage.getItem('refresh_token');
        const form = containerRef.current;
        form.setAttribute("method", "post");
        if (redirectURI) form.setAttribute("action", sessionStorage.getItem(AppConstants.REDIRECT_URI));
        else form.setAttribute("action", magentoHostURL + '/shop/ping/account/authenticate');

        const accToken = document.createElement("input");
        accToken.setAttribute("type", "hidden");
        accToken.setAttribute("name", "access_token");
        accToken.setAttribute("value", access_token);

        const refToken = document.createElement("input");
        refToken.setAttribute("type", "hidden");
        refToken.setAttribute("name", "refresh_token");
        refToken.setAttribute("value", refresh_token);

        form.append(accToken);
        form.append(refToken);
        form.submit();
    }

    function onSetPasswordSubmit(e) {
        e.preventDefault();
        setError(false);
        enableLoadingStyle();
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        if (
            containsUL &&
            containsLL &&
            containsN &&
            containsSC &&
            contains8C &&
            passwordMatch
        ) {
            const accNo = sessionStorage.getItem('accountNumber');
            patchNewPassword(passwordOne, accNo).then(res => {
                if (isMobile) {
                    return getTillAuthCode(userName, passwordOne).then(flowId => {
                        sessionStorage.setItem(AppConstants.FLOWID, flowId);
                        setShow(false);
                    }).catch(err => {
                        disableLoadingStyle();
                        if (err.error && err.error.code && err.error.code === 'INVALID_CREDENTIALS') {
                            sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage_Mob: Entered incorrect password');
                            setError(PageContents.INVALID_PASSWORD);
                        } else if (err.error && err.error.code && err.error.code === AppConstants.ACCOUNT_LOCKED) {
                            sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage_Mob: Account locked');
                            setError(AppConstants.ACCOUNT_LOCKED_MSG);
                        } else {
                            sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage_Mob: Server issue while verifying the password');
                            setError(AppConstants.SERVER_ISSUE);
                        }
                    })
                }

                return authUserWithPing(userName, passwordOne).then(res => {
                    const data = res.data;
                    if (!data && !('access_token' in data) && !('refresh_token' in data)) return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 5'));
                    sessionStorage.setItem('access_token', data.access_token);
                    sessionStorage.setItem('refresh_token', data.refresh_token);
                    setShow(false);
                    //return postAccessTokenToMagento(data.access_token, cb);
                }).catch(err => {
                    disableLoadingStyle();
                    if ('error' in err && 'code' in err.error && err.error.code === 'INVALID_CREDENTIALS') {
                        sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage: Entered incorrect password');
                        setError(PageContents.INVALID_PASSWORD);
                    } else if (err.error && err.error.code && err.error.code === AppConstants.ACCOUNT_LOCKED) {
                        sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage: Account locked');
                        setError(AppConstants.ACCOUNT_LOCKED_MSG);
                    } else {
                        sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage: Server issue while verifying the password');
                        setError(AppConstants.SERVER_ISSUE);
                    }
                });

                /*authUserAndPostToMag(email, passwordOne, function (res) {
                    disableLoadingStyle();
                    if ('error' in res) {
                        if (res.error.code === 'INVALID_CREDENTIALS') {
                            setError(PageContents.INVALID_PASSWORD);
                        } else {
                            setError(AppConstants.SERVER_ISSUE);
                        }
                    } else {
                        setShow(false);
                    }
                });*/
            }).catch(res => {
                disableLoadingStyle();
                sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage' + (isMobile ? '_Mob: ' : ': ') + res.error.code);
                setError(res.error.code);
            });
        } else {
            disableLoadingStyle();
            sendFormErrEvent('Ping: SetPasswordForm', 'PingSetPassPage' + (isMobile ? '_Mob: ' : ': ') + 'new password did not meet requirements');
            if (brand.name === AppConstants.BOOST_BRAND) setError("Please verify below password validations");
            else {
                if (!contains8C || !containsLL || !containsUL || !containsSC || !containsN) setError("Your password must be at least 8 characters long, contain at least one number, one special character, one uppercase and one lowercase letters.");
                else setError(PageContents.PASS_MATCH_ERR);
            }
        }
    }

    const validatePassword = () => {
        if (passwordOne.toLowerCase() !== passwordOne) setContainsUL(true);
        else setContainsUL(false);

        // has lowercase letter
        if (passwordOne.toUpperCase() !== passwordOne) setContainsLL(true);
        else setContainsLL(false);

        // has number
        if (/\d/.test(passwordOne)) setContainsN(true);
        else setContainsN(false);

        // has special character
        if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(passwordOne))
            setContainsSC(true);
        else setContainsSC(false);

        // has 8 characters
        if (passwordOne.length >= 8) setContains8C(true);
        else setContains8C(false);

        // passwords match
        if ((passwordOne !== "" && passwordOne === passwordTwo) || brand.name === AppConstants.BOOST_BRAND)
            setPasswordMatch(true);
        else setPasswordMatch(false);
    };

    useEffect(() => {
        if (
            containsUL &&
            containsLL &&
            containsN &&
            containsSC &&
            contains8C

        ) {
            setBtnDisable(false);
        } else setBtnDisable(true);
    }, [containsUL, containsLL, containsN, containsSC, contains8C, passwordMatch, brand.name]);

    useEffect(() => {
        setShow(prevState => !prevState);
    }, []);

    useEffect(validatePassword, [passwordOne, passwordTwo, brand.name]);

    if (!access_token) {
        <Redirect to="/" />
    }

    return (
        <div className="container-main flex flex-center-all">
            <HeaderComp isBackBtnReq={brand.name === AppConstants.BOOST_BRAND ? "true" : "false"} redirectURI={"/" + redirectURI} loading={loading} onClickCallback={onBackClick} />
            <CSSTransition nodeRef={nodeRef} in={show} timeout={transitionTo === 'Back' ? 200 : 800} classNames={transitionTo === 'Back' ? slideTransition : slideExitTransition} onExited={() => {
                if (transitionTo === 'Back') history.push("/" + redirectURI);
                else {
                    const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                    const flowId = sessionStorage.getItem(AppConstants.FLOWID);
                    sessionStorage.clear();
                    if (isMobile) window.location.href = pingHostURL + '/as/' + flowId + '/resume/as/authorization.ping';
                }
            }} onExit={() => {
                //const auth_code = sessionStorage.getItem('auth_code');
                const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                if (transitionTo === 'Continue') {
                    if (!isMobile) {
                        // window.location.href = magentoHostURL + '/shop/ping/account/authenticate?refresh_token=' + auth_code;
                        submitToMag();
                    }
                }
            }} mountOnEnter unmountOnExit>
                <div className="identity-body flex flex-center-all" ref={nodeRef} >
                    <div className="container-load" >
                        <form onSubmit={onSetPasswordSubmit} action="/" autoComplete="on" ref={containerRef} className="email-box flex">
                            {
                                brand.name !== AppConstants.GENESIS_BRAND && !!error ?
                                    <ErrorBlock message={error} />
                                    :
                                    null
                            }

                            <h1 className="primary-text">{PageContents.SET_PASS_PRIM_TEXT}</h1>
                            <div className='prof-container transparent'>
                                <p className="secondary-text" >{PageContents.SET_PASS_SEC_TEXT}</p>
                            </div>
                            <input type="email" className="hidden-block" name="username" value={userName} autoComplete="username" readOnly />

                            <PassComp id="new-pass-text-field-1" autoCompleteName="new-password" isDisabled={loading} error={error} isFocussed="true" label={PageContents.CREATE_PASS_LABEL} type="password" name="password" onInputChange={onPassChange} onFocus={() => checkRef.current.style.display = "block"} onBlur={() => {
                                if (AppConstants.BOOST_BRAND !== brand.name) checkRef.current.style.display = "none"
                            }} />

                            <br />

                            <div className={styles['pass-rules']} ref={checkRef}>
                                <ul className={styles['no-bullets']}>
                                    {
                                        mustContainData.map((data) => {
                                            return (
                                                <li key={data[0]}  ><CheckMark isValid={data[1]} brandName={brand.name} /> <span className={styles['mandatory-text']}>{data[0]}</span></li>
                                            )
                                        })
                                    }
                                </ul>

                            </div>

                            {
                                brand.name !== AppConstants.BOOST_BRAND ? <PassComp id="new-pass-text-field-2" autoCompleteName="new-password" isDisabled={loading} error={error} isFocussed="false" label={PageContents.CNFRM_PASS_LABEL} type="password" name="confirmPass" pasteEnable="false" onInputChange={onPassConfirmChange} />
                                    :
                                    null
                            }



                            {
                                brand.name === AppConstants.GENESIS_BRAND && !!error ?
                                    <p className="err-block">{error}</p>
                                    :
                                    null
                            }



                            <button disabled={btnDisable} type="submit" id="prim-button" className="modal--button--primary primary" > {PageContents.CREATE_PASS_BTN_TEXT} </button>
                        </form>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default SetPassword;
