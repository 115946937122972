import boostStyles from './BoostErrorBlock.module.css';
import genesisStyles from './GenesisErrorBlock.module.css';
import infiniteStyles from './InfiniteErrorBlock.module.css';
import * as AppConstants from '../../AppConstants';
import AlertIcon from '../Icons/AlertIcon/AlertIcon';
import InfoIcon from '../Icons/InfoIcon/InfoIcon';

function ErrorBlock(props) {
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    let styles;

    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }

    return (
        <div className={styles['error-div'] + " flex"}>            
            {!!props.isInfo ? <div className={styles['info-icon-div']}><InfoIcon /></div> : <div className={styles['alert-icon-div']}><AlertIcon /></div>}
            <div className={styles['err-message']}>{props.message}</div>
        </div>
    );

}

export default ErrorBlock;