import axios from "axios";
import * as AppConstants from '../AppConstants';

const pingHostURL = process.env.REACT_APP_PING_HOST;

function getError(code, msg) {
    return {
        'error': {
            'code': code,
            'message': msg
        }
    }
}

function getFlowID(redirectURL, pingClientID) {
    return axios({
        url: pingHostURL + '/as/authorization.oauth2?client_id=' + pingClientID + '&redirect_uri=' + redirectURL + '&response_type=code',
        method: "get",
        withCredentials: true
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));
    });
}

function initiateAuthSess(flowId) {
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId,
        method: "get",
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate'
        }
    }).catch(err => {
        return Promise.reject(getError('PING_ERROR', err));

    });
}

function postCredentials(email, pass, flowId) {
    return axios({
        url: pingHostURL + '/pf-ws/authn/flows/' + flowId,
        method: "POST",
        withCredentials: true,
        headers: {
            'X-XSRF-Header': 'PingFederate',
            'Content-Type': 'application/vnd.pingidentity.checkUsernamePassword+json'
        },
        data: {
            'username': email,
            'password': pass
        }
    }).catch(err => {
        if (err.response && err.response.data) {
            const errData = err.response.data;
            if (errData.code && errData.code === 'VALIDATION_ERROR') {
                if(errData.details && errData.details.length >= 0 && errData.details[0].userMessage === 'authn.srvr.msg.account.locked'){
                    return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED, err));
                }
                return Promise.reject(getError('INVALID_CREDENTIALS', err));
            }
        }
        return Promise.reject(getError('PING_ERROR', err));
    });
}

/*function getAuthCode(flowId) {
    let auth_code;
    return axios({
        url: pingHostURL + '/as/' + flowId + '/resume/as/authorization.ping',
        method: 'get',
        withCredentials: true
    }).then(res => {        
        const codeURLStr = res.request.responseURL;
        if(codeURLStr && codeURLStr.includes("error=") ){
            return Promise.reject(getError(AppConstants.ACCOUNT_LOCKED, 'Account locked'));
        } else if(!codeURLStr || !codeURLStr.includes("code=")) {
            return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 1'));
        }
        auth_code = codeURLStr.split("code=")[1];              
        return auth_code;
    })
}*/

function getTillAuthCode(email, pass) {
    let flowId;
    const redirectURL = sessionStorage.getItem(AppConstants.REDIRECT_URI);
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const pingClientID = brand.mobPFClient;
    /*let redirectURL = "https://" + window.location.hostname;
    if(window.location.hostname.includes("localhost")){
        redirectURL = 'https://boost-dev.dish.com';
    }*/
    return getFlowID(redirectURL, pingClientID).then(res => {
        const flowURLStr = res.request.responseURL;
        if (!flowURLStr || !flowURLStr.includes("flowId=")) {
            return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 1'));
        }
        flowId = flowURLStr.split("flowId=")[1];;
        return initiateAuthSess(flowId);
    }).then(res => {
        return postCredentials(email, pass, flowId);
    }).then(res => {
        return Promise.resolve(flowId);
    });
}

export {
    getError,
    getTillAuthCode
};