function sha256(plain) { // returns promise ArrayBuffer    
    const encoder = new TextEncoder();
    const data = encoder.encode(plain); // encodes the string into Unit8Array of length equal to inputString length   
    return window.crypto.subtle.digest('SHA-256', data);    
}

function base64urlencode(a) {
    let str = "";
    let bytes = new Uint8Array(a);
    let len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

async function challenge_from_verifier(v) {
    let hashed = await sha256(v);    
    let base64encoded = base64urlencode(hashed);    
    return base64encoded;
}
function dec2hex(dec) {
    return ('0' + dec.toString(16)).substr(-2)
}

function generateRandomString() {
    var array = new Uint32Array(22);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
}


function generateString(characters, length) {
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}


//Regex [0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}
function generateApigeeCorrID(){
    const characters ='ABCDEFabcdef0123456789';
    const splitStr = generateString(characters, 30);
    const firstElem = generateString('12345', 1);
    const secElem = generateString('89abAB', 1);
    let str = splitStr.slice(0,8)+'-'+splitStr.slice(8,12)+'-'+firstElem+splitStr.slice(12,15)+'-'+secElem+splitStr.slice(15,18)+'-'+splitStr.slice(18,30);
    return str;
}

export { 
    generateRandomString, 
    challenge_from_verifier,
    generateApigeeCorrID 
};