const hostname = window.location.hostname;
export const INVALID_EMAIL = 'Email Address or Phone Number provided does not match our records. Please try again.';
export const LEGACY_CUSTOMER = 'Thank you for being a loyal Boost subscriber! At this time, your account is not supported.'
export const REGEX_EMAIL_FAIL = 'Please enter a valid Phone Number or Email';
export const INVALID_PASSWORD = 'Looks like you entered incorrect password. Please try again.';
export const REGEX_PASS_FAIL = "Please enter a valid Password";
export const REGEX_OTP_FAIL = "Please enter a six digit OTP";
let SERVER_ISSUE_TEMP;
if (hostname && hostname.includes('dev')) SERVER_ISSUE_TEMP = "We don't know whats wrong! Please help";
else SERVER_ISSUE_TEMP = 'Oops something is wrong. Please try again later.';
export const REDIRECT_URI = 'redirectURI';
export const ACTION = 'action';
export const UPDATE_EMAIL = 'updateEmail';
export const IS_MOBILE = 'isMobile';
export const FLOWID = 'flowId';
export const AUTH_CODE = 'auth_code';
export const BRAND = 'brand';
export const BOOST_BRAND = 'Boost';
export const GENESIS_BRAND = 'Genesis';
export const INFINITE_BRAND = 'Infinite';
export const LIMIT_EXCEEDED = 'LIMIT_EXCEEDED';
export const ACCOUNT_LOCKED = 'ACCOUNT_LOCKED';
export const IDENTIFIER_REQ_STATE = 'IDENTIFIER_REQUIRED';
export const AUTH_REQ_STATE = 'AUTHENTICATION_REQUIRED';
export const OTP_REQ_STATE = 'OTP_REQUIRED';
export const MFA_SETUP_STATE = 'MFA_SETUP_REQUIRED';
export const DEVICE_PAIRING_STATE = 'DEVICE_PAIRING_METHOD_REQUIRED';
export const EMAIL_TARGET_STATE = 'EMAIL_PAIRING_TARGET_REQUIRED';
export const SMS_TARGET_STATE = 'SMS_PAIRING_TARGET_REQUIRED';
export const EMAIL_ACTIVATE_STATE = 'EMAIL_ACTIVATION_REQUIRED';
export const SMS_ACTIVATE_STATE = 'SMS_ACTIVATION_REQUIRED';
export const MFA_VERIFY_STATE = 'MFA_VERIFY_STATE';
export const MFA_FAILED = 'MFA_FAILED';
export const INVALID_OTP = 'Please enter a valid code or resend the code if not received';
export const INVALID_OTP_LIMIT_MSG = 'Please resend the code as you have exceeded the invalid attempts limit';
export const PING_OTP_INVALID_ERROR = "VALIDATION_ERROR";
export const PING_OTP_INVALID_LIMIT_ERROR = "INVALID_ACTION_ID";
export const PING_SMS_OTP_INVALID_LIMIT_ERROR = "REQUEST_FAILED";
export const USERNAME = 'userName';
export const USER_EMAIL = 'userEmail';
export const DEVICE_AUTHENTICATION_ID = 'deviceAuthenticationID';
export const FIRSTNAME = 'firstName';
export const LIMIT_EXCEEDED_MESSAGE = 'You have exceeded the password reset limit. Please try again in an hour.';
export const FORGOT_PASS_MODAL_PRIM_TEXT = "Request temporary code";
export const MFA_MODAL_PRIM_TEXT = "Resend temporary code";
export const FRGT_PASS_MODAL_BTN_TXT = "Send Code";
export const EMAIL_MODAL_BTN_TXT = "Download MyBoost";
export const EMAIL_MODAL_PRIM_TEXT = "You are a legacy customer";
export const ACCOUNT_LOCKED_MSG = 'Your account is locked. You can unlock your account by resetting your password via the “forgot password” link below.';
export const PASS_HISTORY_MSG = 'Oops something is wrong. Your new password may have been used before.';
export const SERVER_ISSUE = SERVER_ISSUE_TEMP;
export const SMS_OTP_ISSUE = (onClickFunc) => {
    const errMsg = <span>
        It seems like you're having trouble getting the SMS verification code. <a href='/password' onClick={onClickFunc}> Try using your email to login </a> or call <a href="tel:+18335026678">+1 (833) 502-6678</a> for assistance
    </span>
    return errMsg;
}
export const EMAIL_OTP_ISSUE = (onClickFunc) => {
    const errMsg = <span>
        It seems like you're having trouble getting the email verification code. <a href='/password' onClick={onClickFunc}> Try using your phone number to login </a> or call <a href="tel:+18335026678">+1 (833) 502-6678</a> for assistance
    </span>
    return errMsg;
}
export const CAPTCH_BOT_ERR = 'Oops something is wrong. Please try again later.';
export const CODE_VERIFIER = 'code_verifier';
export const EMAIL_EXISTS = 'EMAIL_EXISTS';
export const ACCOUNT_STATUS = {
    LOCKED: 'LOCKED',
    DISABLED: 'DISABLED',
    ACTIVE: 'ACTIVE',
    HOLD: 'HOLD',
    UNLOCKED: 'UNLOCKED'
}