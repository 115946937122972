import boostStyles from './BoostModalMsgIcon.module.css';
import genesisStyles from './GenesisModalMsgIcon.module.css';
import infiniteStyles from './BoostModalMsgIcon.module.css';
import { Fragment } from 'react';
import * as AppConstants from '../../../AppConstants';

function ModalMsgIcon(props) {

    let styles;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }

    return (
        <Fragment>
            <svg width="48px" height="48px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Message Icon</title>
                <g id="Identity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <g id="Password-Modal-Alert" className={styles['msg-icon']} transform="translate(-695.000000, -344.000000)" strokeWidth="3">
                        <g id="Interface-Essential-/-Password-/-password-lock-1" transform="translate(695.000000, 344.000000)">
                            <g id="password-cursor" transform="translate(1.000000, 1.000000)">
                                <path d="M25.5,25.5 L4.5,25.5 C2.84314575,25.5 1.5,24.1568542 1.5,22.5 L1.5,4.5 C1.5,2.84314575 2.84314575,1.5 4.5,1.5 L40.5,1.5 C42.1568542,1.5 43.5,2.84314575 43.5,4.5 L43.5,16.5" id="Shape"></path>
                                <path d="M10.5,12.7499973 C10.9142136,12.7499973 11.2499973,13.0857864 11.2499973,13.5 C11.2499973,13.9142136 10.9142136,14.2499973 10.5,14.2499973 C10.0857864,14.2499973 9.74999732,13.9142136 9.74999732,13.5 C9.749467,13.3009247 9.82831346,13.1098494 9.96908145,12.9690814 C10.1098494,12.8283135 10.3009247,12.749467 10.5,12.7499973" id="Shape"></path>
                                <path d="M22.5,12.7499973 C22.9142136,12.7499973 23.2499973,13.0857864 23.2499973,13.5 C23.2499973,13.9142136 22.9142136,14.2499973 22.5,14.2499973 C22.0857864,14.2499973 21.7499973,13.9142136 21.7499973,13.5 C21.749467,13.3009247 21.8283135,13.1098494 21.9690814,12.9690814 C22.1098494,12.8283135 22.3009247,12.749467 22.5,12.7499973" id="Shape"></path>
                                <path d="M34.5,12.7499973 C34.9142136,12.7499973 35.2499973,13.0857864 35.2499973,13.5 C35.2499973,13.9142136 34.9142136,14.2499973 34.5,14.2499973 C34.0857864,14.2499973 33.7499973,13.9142136 33.7499973,13.5 C33.749467,13.3009247 33.8283135,13.1098494 33.9690814,12.9690814 C34.1098494,12.8283135 34.3009247,12.749467 34.5,12.7499973" id="Shape"></path>
                                <rect id="Rectangle-path" x="28.5" y="31.5" width="18" height="15" rx="1.5"></rect>
                                <path d="M37.5,22.5 L37.5,22.5 C34.1862915,22.5 31.5,25.1862915 31.5,28.5 L31.5,31.5 L43.5,31.5 L43.5,28.5 C43.5,25.1862915 40.8137085,22.5 37.5,22.5 Z" id="Shape"></path>
                                <path d="M37.5,38.308 C37.9142136,38.308 38.25,38.6437864 38.25,39.058 C38.25,39.4722136 37.9142136,39.808 37.5,39.808 C37.0857864,39.808 36.75,39.4722136 36.75,39.058 C36.75,38.6437864 37.0857864,38.308 37.5,38.308" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Fragment>
    );
}

export default ModalMsgIcon;