import boostStyles from './BoostExpandIcon.module.css';
import genesisStyles from './GenesisExpandIcon.module.css';
import infiniteStyles from './InfiniteExpandIcon.module.css';
import { Fragment } from 'react';
import * as AppConstants from '../../../AppConstants';

function ExpandIcon(props) {

    let styles;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }

    return (
        <Fragment>
            {props.expand === false ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10" className={styles['expand-icon']} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14L12 9L17 14" className={styles['expand-icon']} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>


            )}
        </Fragment>
    );
}

export default ExpandIcon;