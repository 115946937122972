import { useEffect, useState, useRef, Fragment } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { getTillAuthCode } from './../../api/PingIndentity';
import { authUserWithPing, getError } from './../../api/PingIndentityPKCE';
//import { sendTempCode } from './../../api/auth';
import HeaderComp from '../HeaderComp/HeaderComp';
import * as AppConstants from '../../AppConstants';
import * as BoostPageContents from '../../Boost/PageContents';
import * as GenesisPageContents from '../../Genesis/PageContents';
import * as InfinitePageContents from '../../Infinite/PageContents';
import ErrorBlock from '../ErrorBlock/ErrorBlock';
import PassComp from '../PassComp/PassComp';
import ModalComp from "../ModalComp/ModalComp";
import ProfileIcon from "../Icons/ProfileIcon/ProfileIcon";
import { verifyMFAWithPing } from '../../api/PingIdentityMFA';
import { CSSTransition } from "react-transition-group";
import slideTransition from "../CssTransitions/slide.module.css";
import slideExitTransition from "../CssTransitions/slide-exit-delay.module.css";
import { sendFormErrEvent, sendClickEvent } from '../../Utitlity/AdobeAnalytics';
import { testEmail } from '../../Utitlity/Misc';


function PasswordSignIn() {
    const [show, setShow] = useState(false);
    const [pass, setPass] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [transitionTo, setTransitionTo] = useState('Continue');
    const [loadModal, setLoadModal] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);
    //const email = sessionStorage.getItem(AppConstants.USER_EMAIL);
    const userName = sessionStorage.getItem(AppConstants.USERNAME);
    const userNameFormatted = ((/^[0-9]{10}$/).test(userName)) ? getNumberFormat(userName) : userName.toLowerCase();
    //const accNum = sessionStorage.getItem('accountNumber');
    const passChangeReq = sessionStorage.getItem('passChangeReq');
    const redirectURI = sessionStorage.getItem(AppConstants.REDIRECT_URI) ? "?redirect_uri=" + sessionStorage.getItem(AppConstants.REDIRECT_URI) : "";
    const history = useHistory();
    const nodeRef = useRef();
    const linkRef = useRef();
    const newLinkRef = useRef();
    const containerRef = useRef(null);

    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const magentoHostURL = brand.magentoHost;
    const pingHostURL = process.env.REACT_APP_PING_HOST;
    let PageContents;
    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            PageContents = BoostPageContents;
            break;
        case AppConstants.GENESIS_BRAND:
            PageContents = GenesisPageContents;
            break;
        case AppConstants.INFINITE_BRAND:
            PageContents = InfinitePageContents;
            break;
        default:
            PageContents = BoostPageContents;
    }

    useEffect(() => {
        window.adobeDataLayer = window.adobeDataLayer || [];
        const adobeObj = {
            event: 'screen_load',
            web: {
                webPageDetails: {
                    pageViews: {
                        value: 1
                    },
                    name: 'BMWeb|ping-password',
                    domain: brand.name,
                    language: 'en',
                    siteSection: 'password',
                    siteSubSection: 'password',
                    siteSubSubSection: 'password',
                    URL: window.location.href,
                    qsp: window.location.search,
                    platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
                    type: 'login password page'

                }
            }

        }
        window.adobeDataLayer.push(adobeObj);
    }, [brand.name]);

    useEffect(() => {
        if (error) {
            window.scroll({
                top: -1,
                behavior: 'smooth'
            });

        }
    }, [error]);

    function enableLoadingStyle() {
        setBtnDisable(true);
        linkRef.current.classList.add("disabled");
        if (brand.name !== AppConstants.GENESIS_BRAND) newLinkRef.current.classList.add("disabled");
        setLoading(true);
        containerRef.current.classList.add("container-main--loading");
    }

    function disableLoadingStyle() {
        setBtnDisable(false);
        setLoading(false);
        containerRef.current.classList.remove("container-main--loading");
        linkRef.current.classList.remove("disabled");
        if (brand.name !== AppConstants.GENESIS_BRAND) newLinkRef.current.classList.remove("disabled");
    }

    function submitToMag() {
        const access_token = sessionStorage.getItem('access_token');
        const refresh_token = sessionStorage.getItem('refresh_token');
        const form = containerRef.current;
        form.setAttribute("method", "post");
        if (redirectURI) form.setAttribute("action", sessionStorage.getItem(AppConstants.REDIRECT_URI));
        else form.setAttribute("action", magentoHostURL + '/shop/ping/account/authenticate');
        const accToken = document.createElement("input");
        accToken.setAttribute("type", "hidden");
        accToken.setAttribute("name", "access_token");
        accToken.setAttribute("value", access_token);

        const refToken = document.createElement("input");
        refToken.setAttribute("type", "hidden");
        refToken.setAttribute("name", "refresh_token");
        refToken.setAttribute("value", refresh_token);

        form.append(accToken);
        form.append(refToken);
        form.submit();
    }

    useEffect(() => {
        setShow(prevState => !prevState);
    }, []);

    useEffect(() => {
        if (pass.length >= 8) setBtnDisable(false);
        else setBtnDisable(true);
    }, [pass]);

    useEffect(() => {
        if (loadModal && linkRef && linkRef.current) {
            setBtnDisable(true);
            linkRef.current.classList.add("disabled");
            containerRef.current.classList.add("container-main--loading");
        } else if (!loadModal && !loading && linkRef && linkRef.current) {
            setBtnDisable(false);
            linkRef.current.classList.remove("disabled");
            containerRef.current.classList.remove("container-main--loading");
        }
    }, [loadModal, loading]);

    function onPassChange(e) {
        setError(false);
        setPass(e);
    }

    function onBackClick(e) {
        e.preventDefault();
        setTransitionTo('Back');
        setLoadModal(false);
        setShow(false);
        if (brand.name === AppConstants.BOOST_BRAND) {
            sendClickEvent(
                'Header',
                'Back Button',
                "https://id.boostmobile.com/password",
                'BMWeb|ping-password'
            );
        }
    }

    function sendTempCodeWithApigee(e) {
        e.preventDefault();
        setError(false);
        setLoadModal(true);
        /*return sendTempCode(accNum, email).then((res) => {
            history.push("/forgot");
        }).catch(err => {
            disableLoadingStyle();
            if (err.error && err.error.code) return setError(err.error.code);
            setError(AppConstants.SERVER_ISSUE);
        });*/
    }

    function getNumberFormat(userName) {
        return userName.substr(0, 3) + "-" + userName.substr(3, 3) + "-" + userName.substr(6, 4);
    }

    function sendMFACode(e) {
        e.preventDefault();
        setError(false);
        enableLoadingStyle();
        setLoadModal(false);
        return verifyMFAWithPing(userName).then(res => {
            disableLoadingStyle();
            setTransitionTo("mfa");
            if (e.target.href && e.target.href.includes('/otp/signin')) setTransitionTo("/otp/signin");
            setShow(false);
        }).catch(err => {
            disableLoadingStyle();
            const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
            sendFormErrEvent('Ping: PasswordForm', 'PingPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Failed to send one time code');
            setError(AppConstants.SERVER_ISSUE);
        });
    }

    function authUser(e) {
        e.preventDefault();
        setError(false);
        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
        if (pass === '') {
            sendFormErrEvent('Ping: PasswordForm', 'PingPassPage' + (isMobile ? '_Mob: ' : ': ') + 'Password validation failed');
            return setError(AppConstants.REGEX_PASS_FAIL)
        }
        enableLoadingStyle();
        if (isMobile) {
            return getTillAuthCode(userName, pass).then(flowId => {
                sessionStorage.setItem(AppConstants.FLOWID, flowId);
                setShow(false);
            }).catch(err => {
                disableLoadingStyle();
                if (err.error && err.error.code && err.error.code === 'INVALID_CREDENTIALS') {
                    sendFormErrEvent('Ping: PasswordForm', 'PingPassPage_Mob: Entered incorrect password');
                    setError(PageContents.INVALID_PASSWORD);
                } else if (err.error && err.error.code && err.error.code === AppConstants.ACCOUNT_LOCKED) {
                    sendFormErrEvent('Ping: PasswordForm', 'PingPassPage_Mob: Account locked');
                    setError(AppConstants.ACCOUNT_LOCKED_MSG);
                } else {
                    sendFormErrEvent('Ping: PasswordForm', 'PingPassPage_Mob: Server issue while verifying the password');
                    setError(AppConstants.SERVER_ISSUE);
                }
            })
        }

        authUserWithPing(userName, pass).then(res => {
            const data = res.data;
            if (!data && !('access_token' in data) && !('refresh_token' in data)) return Promise.reject(getError('PING_ERROR', 'Ping Invalid Response at step 5'));
            sessionStorage.setItem('access_token', data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
            setShow(false);
            //return postAccessTokenToMagento(data.access_token, cb);
            if (brand.name === AppConstants.BOOST_BRAND) {
                window.adobeDataLayer = window.adobeDataLayer || [];
                const adobeClickObj = {
                    event: 'ui_click',
                    web: {
                        webInteraction: {
                            linkClicks: {
                                value: 1
                            },
                            clickLocation: "Continue Button",
                            name: "Continue",
                            clickName: "Continue",
                            URL: "https://id.boostmobile.com/password",
                            pageName: "BMWeb|ping-password",
                            type: 'other',
                            loginStatus: "loggedIn"
                        }
                    }
                };
                window.adobeDataLayer.push(adobeClickObj);
            }

        }).catch(err => {
            disableLoadingStyle();
            if ('error' in err && 'code' in err.error && err.error.code === 'INVALID_CREDENTIALS') {
                sendFormErrEvent('Ping: PasswordForm', 'PingPassPage: Entered incorrect password');
                setError(PageContents.INVALID_PASSWORD);
            } else if (err.error && err.error.code && err.error.code === AppConstants.ACCOUNT_LOCKED) {
                sendFormErrEvent('Ping: PasswordForm', 'PingPassPage: Account locked');
                setError(AppConstants.ACCOUNT_LOCKED_MSG);
            } else {
                sendFormErrEvent('Ping: PasswordForm', 'PingPassPage: Server issue while verifying the password');
                setError(AppConstants.SERVER_ISSUE);
            }
        });
    }
    if (!userName) {
        return (<Redirect to="/" />);
    } else if (passChangeReq && passChangeReq === true) {
        return (<Redirect to="/forgot" />);
    } else {
        return (
            <Fragment>

                <div className="container-main flex flex-center-all">
                    <ModalComp primText={AppConstants.FORGOT_PASS_MODAL_PRIM_TEXT} showComp={loadModal} closeModal={e => setLoadModal(false)}
                        secText={<Fragment>A temporary code will be sent to <strong> {userNameFormatted} </strong> to change your password </Fragment>}
                        btnText={AppConstants.FRGT_PASS_MODAL_BTN_TXT}
                        sendCode={sendMFACode}
                        brandName={brand.name} />
                    <HeaderComp isBackBtnReq={brand.name === AppConstants.BOOST_BRAND ? "true" : "false"} redirectURI={"/" + redirectURI} loading={loading} onClickCallback={onBackClick} />
                    <CSSTransition nodeRef={nodeRef} in={show} timeout={transitionTo === 'Back' ? 200 : 800} classNames={transitionTo === 'Back' ? slideTransition : slideExitTransition} onExited={() => {
                        if (transitionTo === 'Back') history.push("/" + redirectURI);
                        else if (transitionTo === 'mfa') history.push('/mfa');
                        else if (transitionTo === '/otp/signin') history.push('/otp/signin');
                        else {
                            const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                            const flowId = sessionStorage.getItem(AppConstants.FLOWID);
                            sessionStorage.clear();
                            if (isMobile) window.location.href = pingHostURL + '/as/' + flowId + '/resume/as/authorization.ping';

                        }
                    }} onExit={() => {
                        //const auth_code = sessionStorage.getItem('auth_code');
                        const isMobile = sessionStorage.getItem(AppConstants.IS_MOBILE);
                        if (transitionTo === 'Continue') {
                            if (!isMobile) {
                                //window.location.href = magentoHostURL + '/shop/ping/account/authenticate?refresh_token=' + auth_code;
                                submitToMag();
                            }
                        }
                    }} mountOnEnter unmountOnExit>
                        <div className="identity-body flex flex-center-all" ref={nodeRef} >

                            <div className="container-load flex">
                                <form ref={containerRef} autoComplete="on" action="/" onSubmit={authUser} className="email-box flex" style={{ justifyContent: 'center' }}>
                                    {
                                        brand.name !== AppConstants.GENESIS_BRAND && !!error ?
                                            <ErrorBlock message={error} />
                                            :
                                            null
                                    }

                                    {
                                        brand.name === AppConstants.BOOST_BRAND ?
                                            (<Fragment>
                                                <h1 className="primary-text">
                                                    {PageContents.PASS_PRIM_TEXT}
                                                </h1>
                                                <div className='prof-container'>
                                                    <ProfileIcon brandName={brand.name} />
                                                    <p className="secondary-text sec-text-inside">{userNameFormatted}</p>
                                                </div>
                                            </Fragment>) :

                                            (<Fragment>
                                                <h1 className="primary-text">
                                                    {PageContents.PASS_PRIM_TEXT + " " + sessionStorage.getItem(AppConstants.FIRSTNAME).toLowerCase() + "!"}
                                                </h1>

                                                <p className="secondary-text-ext">{userNameFormatted}</p>

                                                <div className='prof-container transparent'>
                                                    <p className='secondary-text'>{PageContents.PASS_SEC_TEXT}</p>
                                                </div>
                                            </Fragment>)
                                    }


                                    <input type="email" className="hidden-block" name="username" value={userName} autoComplete="username" readOnly />

                                    <PassComp id="current-pass-text-field" autoCompleteName="current-password" error={error} isDisabled={loading} isFocussed="true" label={PageContents.PASS_INPUT_LABEL} type="password" name="password" onInputChange={onPassChange} />

                                    {
                                        brand.name === AppConstants.BOOST_BRAND && !!btnDisable && !loading ?
                                            <p className="err-block">Minimum 8 characters</p>
                                            :
                                            null
                                    }


                                    {
                                        brand.name === AppConstants.GENESIS_BRAND && !!error ?
                                            <p className="err-block">{error}</p>
                                            :
                                            null
                                    }

                                    <button type="submit" disabled={btnDisable} className="modal--button--primary primary" id="password-continue" >{PageContents.PASS_BTN_TEXT}</button>
                                </form>
                            </div>
                            <Link
                                ref={linkRef}
                                to="#"
                                style={{ marginTop: '20px', padding: '0px' }}
                                className="missing-footer"
                                onClick={(e) => {
                                    sendTempCodeWithApigee(e)
                                    if (brand.name === AppConstants.BOOST_BRAND) {
                                        sendClickEvent(
                                            'Forgot Password Button',
                                            "Forgot Password?",
                                            "https://id.boostmobile.com/password",
                                            'BMWeb|ping-password'
                                        );
                                    }
                                }}
                            >
                                Forgot password?
                            </Link>
                            {(brand.name !== AppConstants.GENESIS_BRAND) ? <Link to="/otp/signin" style={{ marginTop: '20px', padding: '0px' }} ref={newLinkRef} className="missing-footer" onClick={sendMFACode}>{testEmail(userName) ? PageContents.PASS_EMAIL_CODE : PageContents.PASS_SMS_CODE}</Link> : null}
                        </div>
                    </CSSTransition >
                </div>
            </Fragment>
        );
    }


}

export default PasswordSignIn;