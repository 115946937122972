import { useEffect, useState, useRef } from 'react';
import * as AppConstants from '../../AppConstants';
import boostStyles from './BoostEmailUpdateSuccess.module.css';
import genesisStyles from './GenesisEmailUpdateSuccess.module.css';
import infiniteStyles from './InfiniteEmailUpdateSuccess.module.css';
import { CSSTransition } from "react-transition-group";
import slideExitTransition from "../CssTransitions/slide-exit-delay.module.css";
import HeaderComp from '../HeaderComp/HeaderComp';
import { sendClickEvent } from '../../Utitlity/AdobeAnalytics';

function EmailUpdateSuccess(props) {
  const [show, setShow] = useState(false);
  const nodeRef = useRef();
  const containerRef = useRef(null);
  const redirectURI = sessionStorage.getItem(AppConstants.REDIRECT_URI);
  const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
  let styles;

  useEffect(() => {
    setShow(prevState => !prevState);
  }, []);

  useEffect(() => {
    //Adobe Analytics Event object for PageViews
    window.adobeDataLayer = window.adobeDataLayer || [];
    const adobeObj = {
      event: 'screen_load',
      web: {
        webPageDetails: {
          pageViews: {
            value: 1
          },
          name: 'BMWeb|Update Email Success',
          domain: brand.name,
          language: 'en',
          siteSection: 'update-email-success',
          siteSubSection: 'update-email-success',
          siteSubSubSection: 'update-email-success',
          URL: window.location.href,
          qsp: window.location.search,
          platform: 'PING:' + process.env.REACT_APP_ENV + ":" + brand.name,
          type: 'login update email success page'

        }
      }

    }
    window.adobeDataLayer.push(adobeObj);
    window.adobeDataLayer.push({
      event: "user_account_update",
      web: {
        user: {
          accountUpdate: {
            updateComplete: {
              value: 1
            }
          },
          updateName: "email update"
        }
      }
    });
  }, [brand.name]);

  const onSubmit = (e) => {
    e.preventDefault();
    sendClickEvent("email update success continue", "email-update-success-contiue", window.location.href, "BMWeb|Update Email Success");
    setShow(false);
  }

  switch (props.brandName) {
    case AppConstants.BOOST_BRAND:
      styles = boostStyles;
      break;
    case AppConstants.GENESIS_BRAND:
      styles = genesisStyles;
      break;
    case AppConstants.INFINITE_BRAND:
      styles = infiniteStyles;
      break;
    default:
      styles = boostStyles;
  }
  //const secondaryText = <span>{`${props.secText}`}</span>;
  return (<div className="container-main flex flex-center-all">
    {brand.name !== AppConstants.INFINITE_BRAND ? <HeaderComp isBackBtnReq="false" loading={false} /> : null}
    <CSSTransition nodeRef={nodeRef} in={show} timeout={800} classNames={slideExitTransition} onExited={() => {
      window.location.href = redirectURI;
    }} mountOnEnter unmountOnExit>


      <div className="identity-body flex flex-center-all" ref={nodeRef} >

        <div className='container-load flex'>
          <form ref={containerRef} autoComplete="on" action="/" onSubmit={onSubmit} className="email-box flex" style={{ justifyContent: 'center' }}>
            <h1 className={"primary-text " + styles['m-b-16']}>
              Success
            </h1>
            <div className={styles['prof-container']}>
              <p className="secondary-text">Your email address has been updated. Please sign in again using the new email address or your phone number.</p>
            </div>

            <button type="submit" className={"modal--button--primary primary " + styles['m-top-32']} > Continue to Sign In</button>
          </form>
        </div>
      </div>
    </CSSTransition >
  </div>
  );
}

export default EmailUpdateSuccess;