import { useState, useRef, useEffect } from 'react';
import boostStyles from './BoostHeaderComp.module.css';
import genesisStyles from './GenesisHeaderComp.module.css';
import infiniteStyles from './InfiniteHeaderComp.module.css';
import { Link } from 'react-router-dom';
import * as AppConstants from '../../AppConstants';
import BackButton from '../Icons/BackButton/BackButton';
import LogoIcon from '../Icons/LogoIcon/LogoIcon';
import ExpandIcon from '../Icons/ExpandIcon/ExpandIcon'

function HeaderComp(props) {
    const loaderRef = useRef(null);
    const backRef = useRef(null);
    const brand = JSON.parse(sessionStorage.getItem(AppConstants.BRAND));
    const [expanded, setExpanded] = useState(false);
    const initLang = window.location.hostname.includes("es.boostmobile.com") ? 'ES' : 'EN';
    const [currentLang, setCurrentLang] = useState(initLang);
    let styles;
    switch (brand.name) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            break;
        default:
            styles = boostStyles;
    }
    const isBackBtnReq = props.isBackBtnReq && props.isBackBtnReq.includes("true");
    useEffect(() => {
        loaderRef.current.style.display = props.loading && props.loading === true ? 'block' : 'none';
        if (backRef && backRef.current) {
            if (props.loading && props.loading === true) backRef.current.classList.add("disabled");
            else backRef.current.classList.remove("disabled");
        }
    }, [loaderRef, props.loading]);

    /*useEffect(() => {
        const logoElem = document.getElementById("identity-logo");        
        let timeoutFunc;
        if(logoElem){
            logoElem.addEventListener('mousedown', function(){
                timeoutFunc = setTimeout(function(){
                    setLoadModal(true);
                }, 5000);
            });
            ['mouseup', 'mouseleave'].forEach(function(e) {
                logoElem.addEventListener(e, function(){
                    clearTimeout(timeoutFunc);
                });
            });
        }
    }); */

    return (
        <div className={styles['header-container']} >
            <div className={styles['header'] + " flex"} >
                {
                    isBackBtnReq ?
                        <Link to={props.redirectURI} onClick={(e) => props.onClickCallback(e)} ref={backRef} className={styles['back']}>
                            <BackButton brandName={brand.name} loading={props.loading} />
                            {/* <img className={styles['back-img']} src={backImg} alt="Back button" /> */}
                            <p className={styles['back-text']}><strong>Back</strong></p>
                        </Link>

                        : null
                }

                {
                    brand.name === AppConstants.GENESIS_BRAND ? <div className={styles['header-sub-cont']}> <LogoIcon id="identity-logo" brandName={brand.name} /> <p className={styles['header-txt']} >Sign in to Project Genesis</p> </div> : <LogoIcon id="identity-logo" brandName={brand.name} />
                }

                {
                    brand.name === AppConstants.BOOST_BRAND && !sessionStorage.getItem(AppConstants.IS_MOBILE) ?
                        <div className={styles['language-selection']} onMouseOver={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)} >
                            <div className={styles['selected-language__container']}>
                                <span className="selected-language">{currentLang}</span>
                                <div className={styles['icon-container']}>
                                    <ExpandIcon brandName={brand.name} expand={expanded} />
                                </div>
                            </div>
                            <div className={styles['language-selection__content']} id='languageContent'>

                                <div className={styles['language-item']}>
                                    <a href="https://www.boostmobile.com/shop/customer/account/login/" onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentLang('EN');
                                        window.location.href = e.currentTarget.href;
                                    }}>EN</a>
                                </div>

                                <div className={styles['language-item']}>
                                    <a href="https://es.boostmobile.com/shop/customer/account/login/" onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentLang('ES');
                                        window.location.href = e.currentTarget.href;
                                    }}>ES</a>
                                </div>

                            </div>
                        </div>
                        : null
                }

            </div>
            <div className={styles["loader"]} ref={loaderRef}></div>
        </div>
    );
}

export default HeaderComp;