import { Fragment } from 'react';
import boostStyles from './BoostBackButton.module.css';
import genesisStyles from './GenesisBackButton.module.css';
import infiniteStyles from './InfiniteBackButton.module.css';
import * as AppConstants from '../../../AppConstants';

function BackButton(props) {
    let styles;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;            
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;            
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;            
            break;
        default:
            styles = boostStyles;
    }

    return (
        <Fragment>
            <svg width="31px" height="30px" viewBox="0 0 31 30" className = {styles['back-img']} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>8F374186-1B78-4ECA-A250-54BD7A773FAD</title>
                <defs>
                    <filter x="-1.9%" y="-13.3%" width="103.7%" height="127.5%" filterUnits="objectBoundingBox" id="filter-1">
                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                            <feMergeNode in="SourceGraphic"></feMergeNode>
                        </feMerge>
                    </filter>
                </defs>
                <g id="Identity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <g id="Mobile-Specs" transform="translate(-12.000000, -15.000000)" className={styles['back-svg-file']} strokeWidth="2">
                        <g id="Group-2" filter="url(#filter-1)">
                            <g id="Group-3" transform="translate(12.000000, 15.000000)">
                                <g id="Regular" transform="translate(5.000000, 5.000000)">
                                    <line x1="21" y1="10" x2="0" y2="10" id="Shape"></line>
                                    <polyline id="Shape" points="9.8 0 0 10 9.8 20"></polyline>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Fragment>
    );
}

export default BackButton;